<template>
  <v-main
    class="fill-height custom-scrollbar"
    style="background-color: #F6F7FB; font-family: 'IBM Plex Sans, sans-serif"
  >
    <create-region-step
      v-if="orderStep === 1"
      v-model="orderStep"
      :order="orderRequest"
      @updateOrder="updateOrder"
    />
    <define-aoi-step
      v-else-if="orderStep === 2"
      v-model="orderStep"
      :order="orderRequest"
      :helper="orderHelper"
      @updateOrder="updateOrder"
    ></define-aoi-step>
    <parameter-step
      v-else-if="orderStep === 3"
      v-model="orderStep"
      :order="orderRequest"
      :helper="orderHelper"
      @updateOrder="updateOrder"
    />
    <resolution-step
      v-else-if="orderStep === 4"
      v-model="orderStep"
      :order="orderRequest"
      :helper="orderHelper"
      @updateOrder="updateOrder"
      @updateHelper="updateHelper"
    />
    <define-time-range
      v-else-if="orderStep === 5"
      v-model="orderStep"
      :order="orderRequest"
      :helper="orderHelper"
      @updateOrder="updateOrder"
      @updateHelper="updateHelper"
    ></define-time-range>
    <scene-selection
      v-else-if="orderStep === 6"
      v-model="orderStep"
      :order="orderRequest"
      :helper="orderHelper"
      @updateOrder="updateOrder"
      @updateHelper="updateHelper"
    />
    <feature-choice-step
      v-else-if="orderStep === 7"
      v-model="orderStep"
      :order="orderRequest"
      :helper="orderHelper"
      :region-details="regionDetails"
      @updateOrder="updateOrder"
      @updateHelper="updateHelper"
    >
    </feature-choice-step>
    <view-and-pay
      v-else-if="orderStep === 8"
      v-model="orderStep"
      :order="orderRequest"
      :helper="orderHelper"
      @updateOrder="updateOrder"
      @updateHelper="updateHelper"
    ></view-and-pay>
  </v-main>
</template>
<script>
import CreateRegionStep from "@/core/components/order/steps/CreateRegionStep.vue";
import DefineAoiStep from "@/core/components/order/steps/DefineAoiStep.vue";
import DefineTimeRange from "@/core/components/order/steps/DefineTimeRange.vue";
import ResolutionStep from "@/core/components/order/steps/ResolutionStep.vue";
import ParameterStep from "@/core/components/order/steps/ParameterStep.vue";
import SceneSelection from "@/core/components/order/steps/SceneSelection.vue";
import ViewAndPay from "@/core/components/order/steps/ViewAndPay.vue";
import FeatureChoiceStep from "@/core/components/order/steps/ServicesChoiceStep.vue";
import axios from "axios";
import { mapActions } from "vuex";

export default {
  name: "OrderRegionView",
  components: {
    FeatureChoiceStep,
    ViewAndPay,
    SceneSelection,
    ParameterStep,
    ResolutionStep,
    DefineTimeRange,
    DefineAoiStep,
    CreateRegionStep
  },
  data: () => ({
    orderStep: 1,
    regionDetails: {},
    orderRequest: {
      regionId: null,
      regionName: "",
      regionDescription: "",
      geojson: {},
      cloudCover: 75,
      products: [],
      sensors: [],
      services: [],
      selectedScenes: [],
      expertEye: true
    },
    orderHelper: {
      timeranges: [{ startDate: "", endDate: "" }],
      seasons: { months: [], years: [] },
      possibleSensors: [],
      resolutions: [],
      sceneCount: [],
      possibleScenes: [],
      scenePriceHistoric: 0,
      scenePriceFuture: 0,
      servicePrice: 0,
      futureRanges: [],
      historicRanges: []
    },
    loading: true
  }),
  computed: {
    regionId() {
      return this.$route.params.regionId;
    }
  },
  methods: {
    ...mapActions("management", ["getOrFetchRegion"]),
    updateOrder(value) {
      this.orderRequest = value;
    },

    updateHelper(value) {
      this.orderHelper = value;
    },

    splitMultiPolygon(mp) {
      const polygonArray = [];
      mp.coordinates.forEach(function(coords) {
        let feat = { type: "Polygon", coordinates: coords };
        polygonArray.push(feat);
      });
      return polygonArray;
    },

    createFeatures(polygons) {
      const features = [];
      polygons.forEach(function(polygon) {
        let featureEntry = {
          type: "Feature",
          properties: {},
          geometry: polygon
        };
        features.push(featureEntry);
      });
      return features;
    },

    createFeatureCollection(features) {
      let geojson = {
        type: "FeatureCollection",
        features: features
      };
      return geojson;
    },

    async getOrderInfo() {
      const response = await axios.get(`/orders/?regionId=${this.regionId}`);
      return response.data[response.data.length - 1]; // data of last order
    },
    setOrderInfos(order, region) {
      const polygons = this.splitMultiPolygon(order.aoi);
      const createFeatures = this.createFeatures(polygons);
      this.orderRequest.geojson = this.createFeatureCollection(createFeatures);
      this.orderRequest.regionName = region.properties.name;
      this.orderRequest.regionDescription = region.properties.description;
      if (order.products.includes("qut")) {
        this.orderRequest.products = order.products.filter(
          product => product !== "qut"
        );
      } else {
        this.orderRequest.products = order.products;
      }
      this.orderRequest.regionId = order.regionId;
      if (region.properties.services) {
        this.orderRequest.services = region.properties.services;
      }
    }
  },

  async created() {
    if (this.regionId) {
      const order = await this.getOrderInfo();
      this.regionDetails = await this.getOrFetchRegion(this.regionId);
      if (order == undefined) {
        this.$router.push({
          name: "Order"
        });
      } else {
        this.setOrderInfos(order, this.regionDetails);
      }
    }
  }
};
</script>

<style scoped></style>
