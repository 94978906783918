<template>
  <div class="d-flex flex-column full-height">
    <div class="flex-grow-0">
      <order-stepper v-model="step" />
      <step-header
        :step="step"
        :title="'order.defineAoiStep.defineAoi'"
        :sub-title="'order.defineAoiStep.defineAoiDescription'"
      ></step-header>
    </div>
    <div class="flex-grow-1 pt-10">
      <div id="aoi-map" class="full-height">
        <div class="map-bar pa-3">
          <v-row class="py-0  justify-center">
            <v-col cols="4" class="text-center justify-center pb-0">
              <v-text-field
                class="white-background mx-10"
                filled
                dense
                hide-details
                rounded
                clearable
                outlined
                v-model="searchTerm"
                :loading="searchLoading"
                :label="$t('order.defineAoiStep.searchWaterBodies')"
                append-icon="mdi-magnify"
                @click:append="searchForWaterBodies"
                @keydown.enter="searchForWaterBodies"
                @click.clear="clearSearchTerm"
                :items="searchResults"
                :error-messages="searchError"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </div>
        <div class="map-bar">
          <v-row>
            <v-col cols="4" class="offset-4">
              <v-slide-y-transition>
                <v-card outlined v-if="searchResults.length">
                  <v-list nav>
                    <v-subheader>{{
                      $t("order.defineAoiStep.results")
                    }}</v-subheader>
                    <v-list-item-group>
                      <v-list-item
                        v-for="entry in searchResults"
                        :key="entry.osm_id"
                        @click="addSearchResultToMap(entry.geojson)"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ entry.display_name }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-slide-y-transition>
            </v-col>
          </v-row>
        </div>
        <map-menu
          :map="map"
          @zoomSelection="zoomToSelection"
          @deleteAll="deleteAllGeometries"
        ></map-menu>
        <v-tooltip left>
          <template #activator="{ on: onTooltip }">
            <v-btn
              id="file-upload"
              tile
              absolute
              top
              right
              fab
              x-small
              elevation="0"
              class="btn-fix"
              v-on="{ ...onTooltip }"
              @click="openUploadWindow"
            >
              <v-icon color="grey darken-3">mdi-upload</v-icon>
            </v-btn>
          </template>
          <input
            type="file"
            ref="fileDiv"
            class="d-none"
            @change="uploadFile"
          />
          <span>{{ $t("order.defineAoiStep.uploadGeometry") }}</span>
        </v-tooltip>
      </div>
    </div>
    <shallow-water-warning></shallow-water-warning>
    <div class="flex-grow-0">
      <v-row
        no-gutters
        class="d-flex flex-row pa-5"
        style="background-color: white; height: 75px"
        align="center"
      >
        <v-col cols="auto">
          <v-btn text @click="previousStep">
            <v-icon>mdi-chevron-left</v-icon>
            {{ $t("order.back") }}
          </v-btn>
        </v-col>
        <v-col class="d-flex flex-row-reverse" cols="auto">
          <v-btn color="secondary" text @click="cancel"
            >{{ $t("cancel") }}
          </v-btn>
        </v-col>
        <v-spacer />
        <v-col cols="auto" class="mr-16" v-if="numberOfRegions > 0">
          <v-row style="color: grey">
            {{ $t("order.defineAoiStep.area") }}
          </v-row>
          <v-row>
            <h2 class="primary--text">
              <span class="font-weight-light">
                {{ geojsonSumArea }} km<sup>2</sup>
              </span>
            </h2>
          </v-row>
        </v-col>

        <v-col class="d-flex flex-row-reverse ml-4" cols="auto">
          <v-btn
            color="primary"
            rounded
            @click="nextStep"
            :disabled="numberOfRegions === 0"
            >{{ $t("continue") }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-overlay v-if="uploadLoading" opacity="0.3" absolute>
      <div>
        <v-progress-circular
          indeterminate
          color="primary"
          size="64"
        ></v-progress-circular>
      </div>
    </v-overlay>
  </div>
</template>

<script>
import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import MapboxConfig from "@/core/plugins/mapbox";
import geojsonArea from "@mapbox/geojson-area";
import axios from "axios";
import bbox from "@turf/bbox";
import booleanEqual from "@turf/boolean-equal";
import booleanContains from "@turf/boolean-contains";
import booleanOverlap from "@turf/boolean-overlap";
import dissolve from "@turf/dissolve";
import { mapActions } from "vuex";
import mapboxMixins from "@/core/mixins/mapbox.mixins";
import MapMenu from "@/core/components/order/steps/defineAoi/MapMenu.vue";
import StepHeader from "@/core/components/order/StepHeader.vue";
import OrderStepper from "@/core/components/order/OrderStepper.vue";
import ShallowWaterWarning from "@/core/components/order/steps/defineAoi/ShallowWaterWarning.vue";
import { postProj } from "@/core/api/proj.api";

export default {
  name: "DefineAoiStep",
  mixins: [mapboxMixins],
  components: { ShallowWaterWarning, OrderStepper, StepHeader, MapMenu },
  props: {
    value: Number,
    order: Object,
    helper: Object
  },
  data() {
    return {
      step: this.value,
      map: null,
      draw: null,
      searchTerm: "",
      searchResults: [],
      searchLoading: false,
      searchError: "",
      isSelecting: false,
      selectedFile: null,
      fileLoadError: false,
      numberOfRegions: 0,
      geojsonSumArea: 0,
      uploadLoading: false
    };
  },
  computed: {
    mapStyle() {
      return this.getMapStyle();
    }
  },
  methods: {
    ...mapActions("app", ["showSnackbar"]),
    openUploadWindow() {
      this.$refs.fileDiv.click();
    },
    multiToSinglePart(feature) {
      if (feature.geometry.type == "MultiPolygon") {
        feature.geometry.coordinates = feature.geometry.coordinates[0];
        feature.geometry.type = "Polygon";
      }
    },
    async uploadFile(event) {
      const fileInput = event.target;
      if (fileInput.files.length > 0) {
        this.uploadLoading = true;

        this.uploadedFile = fileInput.files[0];
        try {
          const convertedFileData = await postProj(this.uploadedFile);
          convertedFileData.features.forEach(feature => {
            this.multiToSinglePart(feature);
          });
          try {
            if (convertedFileData) {
              this.addFeaturesToMap(convertedFileData);
              this.uploadLoading = false;
              this.zoomToSelection();
            }
          } catch (e) {
            console.log(e);
            this.uploadLoading = false;
            this.showSnackbar({
              show: true,
              message: this.$t("order.defineAoiStep.cannotReadFile"),
              color: "error",
              timeout: 6000
            });
          }
        } catch (e) {
          console.log(e);
          this.uploadLoading = false;
          this.showSnackbar({
            show: true,
            message: this.$t("order.defineAoiStep.errorUploadingFile"),
            color: "error",
            timeout: 6000
          });
        }
      }
    },
    nextStep() {
      this.$emit("updateOrder", this.order);
      this.$emit("updateHelper", this.helper);
      this.$emit("input", this.step + 1);
    },
    previousStep() {
      this.$emit("updateOrder", this.order);
      this.$emit("updateHelper", this.helper);
      this.$emit("input", this.step - 1);
    },
    cancel() {
      this.$router.push({ name: "Regions" });
    },
    initMap() {
      mapboxgl.accessToken = MapboxConfig.accessToken;
      this.map = new mapboxgl.Map({
        container: "aoi-map",
        style: this.mapStyle,
        center: [-30, 40],
        zoom: 2
      });

      this.draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          polygon: true,
          trash: true
        }
      });

      this.map.addControl(this.draw, "bottom-right");

      this.map.on("draw.create", entry => {
        const newFeature = entry.features[0];
        const existingFeatures = this.draw.getAll().features;

        this.checkFeatureRemoval(newFeature, existingFeatures);
        this.combineOverlappingFeatures(newFeature, existingFeatures);
        this.numberOfRegions = existingFeatures.length;
      });

      this.map.on("draw.update", () => {
        this.order.geojson = this.draw.getAll();
        this.calculatePolygonsArea();
        this.helper.possibleScenes = [[]];
        this.order.selectedScenes = [];
      });

      this.map.on("draw.delete", () => {
        this.numberOfRegions = this.draw.getAll().features.length;
      });
    },
    async searchForWaterBodies() {
      this.searchError = "";
      this.searchLoading = true;
      const url = `https://nominatim.openstreetmap.org/search.php?q=${this.searchTerm}&polygon_geojson=1&format=jsonv2`;
      const response = await axios.get(url, {
        transformRequest: (data, headers) => {
          delete headers["Authorization"];
          return data;
        }
      });

      const searchResults = response.data;
      let waterBodies = searchResults.filter(entry => entry.type === "water");
      if (waterBodies.length === 0) {
        this.searchError = "No results";
      }
      this.searchResults = waterBodies;
      this.searchLoading = false;
    },
    clearSearchTerm() {
      this.searchError = "";
      this.searchResults = [];
      this.searchTerm = "";
    },

    addGeoJsonToSelection(feature) {
      const existingFeatures = this.draw.getAll().features;
      const isExisting = existingFeatures.some(existingFeature => {
        return booleanEqual(existingFeature.geometry, feature);
      });
      if (!isExisting) {
        this.numberOfRegions += 1;
        const addedFeatureId = this.draw.add(feature);
        feature.id = addedFeatureId[0];
        this.clearSearchTerm();
        this.zoomToBBox(feature);
        this.checkFeatureRemoval(feature, existingFeatures);
        this.combineOverlappingFeatures(feature, existingFeatures);
      } else {
        this.showSnackbar({
          show: true,
          message: this.$t("order.defineAoiStep.doubledAOIsMessage"),
          color: "warning",
          timeout: 6000
        });
      }
    },
    addSearchResultToMap(feature) {
      this.addGeoJsonToSelection(feature);
      this.clearSearchTerm();
      this.zoomToBBox(feature);
    },
    zoomToBBox(geojson) {
      const geojson_bbox = bbox(geojson);
      this.map.fitBounds(geojson_bbox, { padding: 100 });
    },
    zoomToSelection() {
      const selectionGeojson = this.draw.getAll();
      this.zoomToBBox(selectionGeojson);
    },
    addFeaturesToMap(geojson) {
      geojson.features.forEach(feature => {
        this.addGeoJsonToSelection(feature.geometry);
      });
    },
    calculatePolygonsArea() {
      let sumArea = 0;
      this.order.geojson.features.forEach(feature => {
        const area = geojsonArea.geometry(feature.geometry);
        sumArea += area;
      });
      const orgSumArea = ((sumArea / 1000000) * 1000) / 1000; // square kilometer
      this.geojsonSumArea = orgSumArea.toFixed(2); // square kilometer
    },
    deleteAllGeometries() {
      this.draw.deleteAll();
      this.numberOfRegions = 0;
    },
    combineOverlappingFeatures(newFeature, existingFeatures) {
      existingFeatures = this.draw.getAll().features;
      existingFeatures.forEach(existingFeature => {
        if (newFeature.type === "MultiPolygon") {
          return; // still check other, non multipolygon features
        }
        if (existingFeature.id !== newFeature.id) {
          const overlappingFeature = booleanOverlap(
            existingFeature,
            newFeature
          );
          // Inform user that the new polygons was cobine with existing polygon
          if (overlappingFeature) {
            existingFeature.properties.combine = "yes";
            existingFeatures[existingFeatures.length - 1].properties.combine =
              "yes";
          } else {
            existingFeature.properties.combine = "no";
          }
        }
      });
      if (existingFeatures.length > 1) {
        if (
          existingFeatures.filter(
            features => features.properties.combine == "yes"
          ).length
        ) {
          const combined = dissolve(
            {
              type: "FeatureCollection",
              features: existingFeatures
            },
            { propertyName: "combine" }
          );
          this.deleteAllGeometries();
          this.addFeaturesToMap(combined);
        }
      }
      this.updateOrder();
    },
    checkFeatureRemoval(newFeature, existingFeatures) {
      // check if new feature contains one of the previous ones or the other way round
      const removedFeatures = this.removeExistingContainedFeatures(
        newFeature,
        existingFeatures
      );
      const newFeatureRemoved = this.removeNewFeatureInCaseContained(
        newFeature,
        existingFeatures
      );
      if (removedFeatures || newFeatureRemoved) {
        this.showSnackbar({
          show: true,
          message: this.$t("order.defineAoiStep.removedCoveredPolygonsHint"),
          color: "warning"
        });
      }
    },
    removeExistingContainedFeatures(newFeature, existingFeatures) {
      let removedFeatures = false;
      for (let existingFeature of existingFeatures) {
        if (
          existingFeature.geometry.type === "MultiPolygon" &&
          existingFeatures.length > 1
        ) {
          this.showSnackbar({
            show: true,
            message: this.$t(
              "order.defineAoiStep.noContainsCheckForMultipolygonsHint"
            ),
            color: "warning"
          });
          continue; // still check other, non multipolygon features
        }

        if (existingFeature.id !== newFeature.id) {
          const newEntrycontains = booleanContains(newFeature, existingFeature);
          if (newEntrycontains === true) {
            // Inform user that new polygon contained one or more polygons. Those were removed
            removedFeatures = true;
            this.draw.delete(existingFeature.id);
          }
        }
      }
      return removedFeatures;
    },
    removeNewFeatureInCaseContained(newFeature, existingFeatures) {
      for (const existingFeature of existingFeatures) {
        if (existingFeature.geometry.type === "MultiPolygon") {
          continue; // still check other, non multipolygon features
        }
        if (existingFeature.id !== newFeature.id) {
          const existingContains = booleanContains(existingFeature, newFeature);
          // Inform user that the new polygon was already contained by an existing one
          if (existingContains === true) {
            if (newFeature?.id !== undefined) {
              this.draw.delete(newFeature.id);
            }
            return true;
          }
        }
      }
      return false;
    },
    updateOrder() {
      this.order.geojson = this.draw.getAll();
      this.calculatePolygonsArea();
      this.helper.possibleScenes = [[]];
      this.order.selectedScenes = [];
    }
  },
  watch: {
    numberOfRegions() {
      this.updateOrder();
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initMap();
      if (this.order.geojson?.features) {
        this.addGeoJsonToSelection(this.order.geojson);
        this.zoomToSelection();
      }
    });
  }
};
</script>

<style scoped>
.full-height {
  height: 100%;
}

.map-bar {
  display: flex;
  position: absolute;
  width: 100%;
  z-index: 1 !important;
  justify-content: space-between;
}

.white-background >>> .v-input__control .v-input__slot {
  background: white !important;
}

>>> .mapboxgl-ctrl-group > button {
  width: 36px;
  height: 36px;
}
.basemap-wrapper {
  margin-top: 0 !important;
}

#file-upload {
  margin-top: 141px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.btn-fix {
  border: 1px solid lightgrey;
  margin-right: -7px;
  background-color: white;
  padding: 18px 18px;
}
</style>
