<template>
  <v-stepper v-model="orderStep" @change="changeOrder" class="mx-10">
    <v-stepper-header>
      <v-stepper-step :complete="orderStep > 1" step="1">
        {{ $t("order.project") }}
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :complete="orderStep > 2" step="2">
        {{ $t("order.aoi") }}
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :complete="orderStep > 3" step="3">
        {{ $t("order.products") }}
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :complete="orderStep > 4" step="4">
        {{ $t("order.resolutions") }}
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :complete="orderStep > 5" step="5">
        {{ $t("order.timeRange") }}
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :complete="orderStep > 6" step="6">
        {{ $t("order.scenes") }}
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :complete="orderStep > 7" step="7">
        {{ $t("order.services") }}
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :complete="orderStep > 8" step="8">
        {{ $t("order.viewAndPay") }}
      </v-stepper-step>
    </v-stepper-header>
  </v-stepper>
</template>

<script>
export default {
  name: "OrderStepper",
  props: {
    value: Number
  },
  data() {
    return {
      orderStep: this.value
    };
  },
  methods: {
    changeOrder() {
      this.$emit("input", this.orderStep);
    }
  }
};
</script>

<style>
.v-stepper {
  background-color: transparent !important;
  box-shadow: none !important;
}
</style>
