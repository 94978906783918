<template>
  <div>
    <v-tooltip left>
      <template #activator="{ on: onTooltip }">
        <v-btn
          id="sensor-switch-s2"
          tile
          absolute
          top
          right
          fab
          @click="addTiles('s2')"
          x-small
          elevation="0"
          class="btn-fix"
          v-on="{ ...onTooltip }"
        >
          <v-icon v-if="loadingS2">mdi-loading mdi-spin</v-icon>
          <span v-else>S2</span>
        </v-btn>
      </template>

      <span>{{ $t("order.defineAoiStep.showS2Tiles") }}</span>
    </v-tooltip>
    <v-tooltip left>
      <template #activator="{ on: onTooltip }">
        <v-btn
          id="sensor-switch-lsat"
          tile
          absolute
          top
          right
          fab
          x-small
          :disabled="loadingLsat"
          @click="addTiles('lsat')"
          elevation="0"
          class="btn-fix"
          v-on="{ ...onTooltip }"
        >
          <v-icon v-if="loadingLsat">mdi-loading mdi-spin</v-icon>
          <span v-else>Lsat</span>
        </v-btn>
      </template>

      <span>{{ $t("order.defineAoiStep.showLsatTiles") }}</span>
    </v-tooltip>
    <!--    <v-list-->
    <!--      :style="{-->
    <!--        'margin-top': iconStyles && iconStyles.marginTop ? '44px' : ''-->
    <!--      }"-->
    <!--    >-->
    <!--      <span class="pl-2 font-weight-bold">{{ $t("basemapLayer") }}</span>-->
    <!--      <v-divider></v-divider>-->
    <!--      <v-list-item v-for="item in sensors" :key="item" @click="addTiles(item)">-->
    <!--        <v-list-item-title>{{ $t(item) }}</v-list-item-title>-->
    <!--      </v-list-item>-->
    <!--      <v-list-item>-->
    <!--        <v-list-item-action>-->
    <!--          <v-checkbox @change="toggleTerrain">-->
    <!--            <template v-slot:label>-->
    <!--              <div class="pl-2">Terrain</div>-->
    <!--            </template>-->
    <!--          </v-checkbox>-->
    <!--        </v-list-item-action>-->
    <!--      </v-list-item>-->
    <!--    </v-list>-->
  </div>
</template>

<script>
import mapServices from "@/core/services/map.services";
export default {
  name: "ShowSensorTiles",
  props: ["map", "layers", "iconStyles"],
  data: function() {
    return {
      openMenu: false,
      sensors: ["landsat", "sentinel-2"],
      showS2: false,
      loadingS2: false,
      showLsat: false,
      loadingLsat: false
    };
  },
  methods: {
    async addTiles(sensor) {
      console.log(sensor);
      if (sensor == "s2") {
        this.loadingS2 = true;
        this.showS2 = !this.showS2;
        await mapServices.showS2TileGrid(this.map, this.showS2);
        this.loadingS2 = false;
      } else if (sensor == "lsat") {
        this.loadingLsat = true;
        this.showLsat = !this.showLsat;
        await mapServices.showLsatTileGrid(this.map, this.showLsat);
        this.loadingLsat = false;
      }
    }
  }
};
</script>

<style scoped>
#sensor-switch-lsat {
  margin-top: 210px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

#sensor-switch-s2 {
  margin-top: 178px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.btn-fix {
  border: 1px solid lightgrey;
  margin-right: -7px;
  background-color: white;
  padding: 18px 18px;
}

.btn-fix:focus::before {
  opacity: 0 !important;
}

.btn-fix:hover::before {
  opacity: 0.08 !important;
}
</style>
