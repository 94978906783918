<template>
  <div>
    <span class="heading-4-bold">
      {{ $t("order.viewAndPayStep.account") }}
    </span>
    <span class="body-default pl-3">
      {{ $t("order.viewAndPayStep.pleaseChooseAccount") }}</span
    >
    <v-radio-group v-model="currentAccount" hide-details>
      <v-card
        class="account-card mb-5"
        :style="
          selectedAccount === account.id
            ? 'border: 1px solid #40ADD5;'
            : 'border: 1px solid white;'
        "
        v-for="account in creditAccounts"
        :key="account.id"
        :disabled="!account.possible"
      >
        <v-card-title class="account-title heading-4-bold">
          <v-radio :label="account.name" :value="account.id" color="#40ADD5">
          </v-radio>
        </v-card-title>
        <v-card-text class="account-text justify-space-between ">
          <span>{{ $t("order.viewAndPayStep.balance") }}:</span>
          <span
            class="heading-4"
            :style="{ color: account.balance <= 0 ? 'red' : 'green' }"
          >
            <span v-if="account.balance > 0">+ </span
            >{{ account.balance }} credits</span
          >
        </v-card-text>

        <!--          <v-divider v-if="currentAccount === account.id" class="px-7" />-->
        <!--          <v-row class="pl-8" v-if="currentAccount === account.id">-->
        <!--            <v-col cols="auto" style="font-weight: normal">-->
        <!--              {{ $t("order.afterThisOrder") }}:-->
        <!--            </v-col>-->
        <!--            <v-col class="pb-0 ">-->
        <!--              {{ account.balance - price }}-->
        <!--            </v-col>-->
        <!--          </v-row>-->
      </v-card>
    </v-radio-group>
    <v-row class="mr-1">
      <span v-if="loaded && possibleAccounts.length < 1" style="color: red"
        >{{ $t("order.viewAndPayStep.messageOverCreditLimits") }}
        {{ $t("order.viewAndPayStep.messageChangeOrder") }}
      </span>
      <v-spacer />
      <purchase-credit-dialog
        :dialog="requestCreditDialog"
        :priceSum="pricesum"
        @update-dialog="handlePurchaseDialog"
        @close-dialog="handleCloseDialog"
        @open-success="handleSuccessDialog"
      ></purchase-credit-dialog>
    </v-row>

    <purchase-success-dialog
      :dialog="requestSuccessDialog"
      @close-dialog="handleCloseDialog"
    ></purchase-success-dialog>
  </div>
</template>

<script>
import creditMixin from "@/core/mixins/credit.mixin";
import PurchaseCreditDialog from "@/core/components/order/steps/viewAndPay/PurchaseCreditDialog";
import PurchaseSuccessDialog from "@/core/components/order/steps/viewAndPay/PurchaseSuccessDialog.vue";

export default {
  name: "accountCards",
  mixins: [creditMixin],
  components: { PurchaseSuccessDialog, PurchaseCreditDialog },
  props: {
    value: Number,
    order: Object,
    pricesum: Number
  },
  data() {
    return {
      step: this.value,
      startMenu: false,
      clickSend: false,
      currentAccount: null,
      creditAccounts: [],
      possibleAccounts: [],
      loaded: false,
      selectedAccount: null,
      requestCreditDialog: false,
      requestSuccessDialog: false
    };
  },
  watch: {
    pricesum() {
      this.loaded = false;
      this.checkForAccounts();
      this.selectedAccount = this.currentAccount;
      this.loaded = true;
    },
    currentAccount() {
      this.$emit("setAccount", this.currentAccount);
    }
  },
  methods: {
    async checkForAccounts() {
      this.possibleAccounts = await this.requestPossibleAccounts(this.pricesum);
      this.creditAccounts.forEach(account => {
        const possibility = this.possibleAccounts.filter(
          acc => acc.id == account.id
        );
        if (possibility.length === 1) {
          account.possible = true;
        } else {
          account.possible = false;
        }
      });
    },
    handlePurchaseDialog(value) {
      this.requestCreditDialog = value;
    },
    handleSuccessDialog(value) {
      this.requestSuccessDialog = value;
    },
    handleCloseDialog() {
      this.requestCreditDialog = false;
      this.requestSuccessDialog = false;
    }
  },
  async created() {
    this.creditAccounts = await this.fetchUserAccounts();
    await this.checkForAccounts();
    // this.currentAccount = this.creditAccounts[0].id;
    this.loaded = true;
  }
};
</script>

<style scoped>
.account-title {
  /* v-card__title */

  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: flex-start;
  padding: 20px 16px;
  gap: 8px;

  height: 68px;

  /* custom/offwhite */
  background: #f6f9f9;
  /* custom/gray01 */
  border-bottom: 1px solid #e9eff5;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.account-card {
  /* v-card/Variant4 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  height: 120px;

  /* shades/white */
  background: #ffffff;
  /* elevation/24 */
  box-shadow: 0px 8px 24px rgba(176, 190, 197, 0.5);
  border-radius: 3px;

  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.account-text {
  /* bottom */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding: 16px;
  gap: 16px;
}
</style>
'
