import moment from "moment";
import axios from "axios";

let priceCalculationMixin = {
  methods: {
    getSceneNumber(startDate, endDate) {
      const startYear = moment(startDate).format("YYYY");
      const endYear = moment(endDate).format("YYYY");
      let startNumber = Number(moment(startDate).format("YYYYMMDD"));
      const endNumber = Number(moment(endDate).format("YYYYMMDD"));

      const startDiffDays = startNumber - startYear * 10000;
      const endDiffDays = endNumber - endYear * 10000;

      const nrQuarters = Math.round(
        Math.ceil((endDiffDays - startDiffDays) / 100, 0) / 3,
        1
      );
      const nrYears = (endYear - startYear + nrQuarters / 4.1).toFixed(1);
      let nrDeductionYears = null;
      if (endYear > 2015) {
        nrDeductionYears = Math.max(2015 - startYear, 0);
      } else {
        nrDeductionYears = nrYears;
      }
      const sceneNr = Math.ceil(
        (nrYears - nrDeductionYears) * 15 + nrDeductionYears * 6
      );
      return sceneNr;
    },
    sumUpPrice(array) {
      let sum = 0;
      array.forEach(price => {
        sum = sum + price.price;
      });
      return sum;
    },

    async callPriceCalculation(order, range, count) {
      const data = {
        aoi: order.geojson,
        start_date: range.startDate,
        end_date: range.endDate,
        wq_products: order.products,
        sensors: Array.from(order.sensors),
        number_scenes: count,
        number_of_aois: order.geojson.features.length
      };
      if (order.sensors.length) {
        const response = await axios.post("/orders/get-price/", data);
        return response.data.price;
      } else {
        return 0;
      }
    }

    // calculateForQuality(order, price) {
    //   if (order.expertEye) {
    //     return price;
    //   } else {
    //     price = price - price * 0.3;
    //     return price.toFixed(0);
    //   }
    // }
  }
};

export default priceCalculationMixin;
