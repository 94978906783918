<template>
  <div class="d-flex flex-column full-height">
    <div class="flex-grow-0">
      <order-stepper v-model="step" />
      <step-header
        :step="step"
        :title="'order.createRegionStep.createProject'"
        :sub-title="'order.createRegionStep.createProjectDescription'"
      ></step-header>
    </div>
    <div class="flex-grow-1 pt-10">
      <no-credit-account-dialog></no-credit-account-dialog>
      <v-container class="px-10">
        <v-row>
          <v-col>
            <v-text-field
              v-if="!definedRegionName"
              v-model="order.regionName"
              :label="$t('order.createRegionStep.regionName')"
              :readonly="definedRegionName"
              required
            ></v-text-field>
            <v-text-field
              v-else
              v-model="order.regionName"
              :label="$t('order.createRegionStep.regionName')"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="order.regionDescription"
              :label="$t('order.createRegionStep.regionDescription')"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div class="flex-grow-0">
      <v-row
        no-gutters
        class="d-flex flex-row pa-5"
        style="background-color: white"
        align="center"
      >
        <v-col>
          <v-btn text @click="close">
            <v-icon>mdi-chevron-left</v-icon>
            {{ $t("order.createRegionStep.regionOverview") }}
          </v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn
            color="primary"
            rounded
            :disabled="!order.regionName.length"
            @click="nextStep"
            >{{ $t("continue") }}</v-btn
          >
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import StepHeader from "@/core/components/order/StepHeader.vue";
import OrderStepper from "@/core/components/order/OrderStepper.vue";
import NoCreditAccountDialog from "@/core/components/order/steps/NoCreditAccountDialog.vue";

export default {
  name: "CreateRegionStep",
  components: { NoCreditAccountDialog, OrderStepper, StepHeader },
  props: {
    value: Number,
    order: Object
  },
  data() {
    return {
      step: this.value,
      definedRegionName: false
    };
  },
  methods: {
    cancel() {
      this.close();
    },
    nextStep() {
      this.$emit("updateOrder", this.order);
      this.$emit("input", this.step + 1);
    },
    close() {
      this.$router.push({ name: "Regions" });
    }
  },
  async created() {
    if (this.order?.regionId != null) {
      this.definedRegionName = true;
    }
  }
};
</script>

<style scoped>
.full-height {
  min-height: 100%;
}
</style>
