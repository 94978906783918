<template>
  <v-container class="px-13">
    <v-row>
      <v-card width="100%" elevation="0">
        <v-card-title class=" py-0" style="height: 50px">
          <span
            class="heading-4-bold"
            @click="searchScenesLoading ? '' : filterpanel()"
            @mouseover="changeCursor('pointer')"
            @mouseout="changeCursor('auto')"
            >{{ $t("order.filter") }}</span
          >
          <span
            class="caption-1  ml-2 pt-1 my-1"
            @click="searchScenesLoading ? '' : filterpanel()"
            @mouseover="changeCursor('pointer')"
            @mouseout="changeCursor('auto')"
            ><span
              >{{ $t("order.maxCloudCover") }}: {{ order.cloudCover }} %;
            </span>
            <span class="pl-2"
              >{{ $t("order.sensors") }}:
              <span v-for="sensor in filteredSensors" :key="sensor">
                {{ sensorDict[sensor] }},
              </span>
            </span>
          </span>

          <v-spacer></v-spacer>
          <v-btn
            rounded
            v-if="allSelected && !panel.length && helper.historicRanges.length"
            :disabled="searchScenesLoading"
            class="mr-1 text-lowercase"
            color="secondary"
            :title="$t('orderRequest.reset')"
            @click="$emit('removeAll')"
          >
            {{ $t("order.sceneSelectionStep.removeAll") }}
          </v-btn>
          <v-btn
            rounded
            :disabled="searchScenesLoading"
            v-if="!allSelected && !panel.length && helper.historicRanges.length"
            class="mr-1 text-lowercase"
            color="secondary"
            :title="$t('orderRequest.reset')"
            @click="$emit('selectAll')"
          >
            {{ $t("order.sceneSelectionStep.selectAllFiltered") }} ({{
              filteredScenes.length
            }})
          </v-btn>
          <v-btn icon color="primary" dark @click="filterpanel">
            <v-icon v-if="panel.length" small>mdi-chevron-up</v-icon>
            <v-icon v-else small :disabled="searchScenesLoading"
              >mdi-chevron-down</v-icon
            >
          </v-btn>
        </v-card-title>

        <v-expansion-panels v-model="panel" multiple>
          <v-expansion-panel>
            <v-expansion-panel-content>
              <v-row class="align-center mt-1">
                <v-col cols="auto">
                  <v-icon large>{{ weatherIcon }}</v-icon>
                </v-col>
                <v-col cols="5">
                  <v-row>
                    <span class="caption-1 font-weight-bold">
                      {{ $t("order.cloudCoverMax") }}
                    </span>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-slider
                        v-model="order.cloudCover"
                        :max="100"
                        hide-details
                        :min="cloudCoverMin"
                        class="align-center"
                      >
                        <template id="slider" v-slot:append>
                          <v-text-field
                            v-model="order.cloudCover"
                            hide-details
                            class="pt-0 mt-0"
                            type="number"
                            style="width: 60px"
                          ></v-text-field>
                        </template>
                      </v-slider>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="auto">
                  <v-icon large>mdi-satellite-variant</v-icon>
                </v-col>
                <v-col>
                  <v-row>
                    <span class="caption-1 font-weight-bold">
                      {{ $t("order.sceneSelectionStep.satellites") }}
                    </span>
                  </v-row>
                  <v-row>
                    <v-col
                      v-for="sensor in helper.possibleSensors"
                      :key="sensor"
                      lg="auto"
                      md="auto"
                      sm="6"
                    >
                      <v-checkbox
                        style="min-width: 110px"
                        hide-details
                        class="pt-2 mt-1"
                        :value="sensor"
                        :label="sensorDict[sensor]"
                        v-model="filteredSensors"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="pt-2">
                <v-spacer />
                <v-col cols="auto">
                  <v-btn
                    rounded
                    v-if="allSelected && helper.historicRanges.length"
                    class="mr-1 text-lowercase"
                    color="secondary"
                    :title="$t('orderRequest.reset')"
                    @click="$emit('removeAll')"
                  >
                    {{ $t("order.sceneSelectionStep.removeAll") }}
                  </v-btn>
                  <v-btn
                    rounded
                    v-if="!allSelected && helper.historicRanges.length"
                    class="mr-1 text-lowercase"
                    color="secondary"
                    :title="$t('orderRequest.reset')"
                    @click="$emit('selectAll')"
                  >
                    {{ $t("order.sceneSelectionStep.selectAllFiltered") }} ({{
                      filteredScenes.length
                    }})
                  </v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-row>
  </v-container></template
>
<script>
export default {
  name: "SceneFilter",
  props: {
    value: Number,
    order: Object,
    helper: Object,
    allSelected: Boolean,
    filteredScenes: Array,
    triggerFilter: Boolean,
    searchScenesLoading: Boolean
  },
  data() {
    return {
      panel: [],
      sensorDict: {
        sn2: "Sentinel-2",
        ls8: "Landsat 8",
        ls9: "Landsat 9",
        sn3: "Sentinel-3",
        sdo: "Planet SuperDove"
      },
      filteredSensors: this.order.sensors,
      cloudCoverMin: 0,
      cloudCoverMax: this.order.cloudCover
    };
  },
  computed: {
    weatherIcon() {
      let icon;
      if (this.order.cloudCover <= 25) {
        icon = "mdi-weather-sunny";
      } else if (this.order.cloudCover >= 75) {
        icon = "mdi-weather-cloudy";
      } else {
        icon = "mdi-weather-partly-cloudy";
      }
      return icon;
    }
  },
  methods: {
    filterpanel() {
      if (this.panel.length > 0) {
        this.panel = [];
      } else {
        this.panel = [0];
      }
    },
    changeCursor(mode) {
      document.querySelector("div").style.cursor = mode;
    }
  },
  watch: {
    triggerFilter() {
      if (!this.triggerFilter) {
        this.panel = [];
      }
    },
    filteredSensors() {
      this.$emit("setFilteredDate", {
        sensors: this.filteredSensors,
        cloudCover: this.order.cloudCover
      });
      this.$emit("applyFilter");
    },
    "order.cloudCover"() {
      this.$emit("setFilteredDate", {
        sensors: this.filteredSensors,
        cloudCover: this.order.cloudCover
      });
      this.$emit("applyFilter");
    }
  },
  created() {
    if (!this.order.sensors.length) {
      this.filteredSensors = this.helper.possibleSensors;
    }
    if (this.helper.historicRanges.length == 0) {
      this.panel = [0];
    }
  }
};
</script>

<style scoped>
.v-expansion-panel::before {
  box-shadow: none;
}
</style>
