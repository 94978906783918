<template>
  <v-menu v-model="openMenu" left offset-x nudge-left="10">
    <template v-slot:activator="{ on: onMenu }">
      <v-tooltip :bottom="!!iconStyles" :left="!iconStyles">
        <template #activator="{ on: onTooltip }">
          <v-btn
            id="basemap-switch"
            tile
            absolute
            top
            right
            elevation="0"
            fab
            x-small
            class="btn-fix"
            v-on="{ ...onMenu, ...onTooltip }"
          >
            <v-icon>mdi-map</v-icon>
          </v-btn>
        </template>

        <span>{{ $t("basemapLayer") }}</span>
      </v-tooltip>
    </template>
    <v-list
      :style="{
        'margin-top': iconStyles && iconStyles.marginTop ? '44px' : ''
      }"
    >
      <span class="pl-2 font-weight-bold">{{ $t("basemapLayer") }}</span>
      <v-divider></v-divider>
      <v-list-item
        v-for="item in layers"
        :key="item.id"
        @click="switchBasemap(item.id)"
      >
        <v-list-item-title>{{ $t(item.id) }}</v-list-item-title>
      </v-list-item>
      <v-list-item>
        <v-list-item-action>
          <v-checkbox @change="toggleTerrain">
            <template v-slot:label>
              <div class="pl-2">Terrain</div>
            </template>
          </v-checkbox>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "BasemapSwitchOrder",
  props: ["map", "layers", "iconStyles"],
  data: function() {
    return {
      openMenu: false
    };
  },
  methods: {
    switchBasemap(layerId) {
      this.map.setLayoutProperty(layerId, "visibility", "visible");
      const otherBasemaps = this.layers.filter(layer => layer.id !== layerId);
      otherBasemaps.forEach(layer => {
        this.map.setLayoutProperty(layer.id, "visibility", "none");
      });
    },
    toggleTerrain(setTerrain) {
      if (setTerrain) {
        this.map.setTerrain({ source: "terrainSource", exaggeration: 2 });
      } else {
        this.map.setTerrain();
      }
    }
  }
};
</script>

<style scoped>
#basemap-switch {
  margin-top: 30px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.btn-fix {
  border: 1px solid lightgrey;
  margin-right: -7px;
  background-color: white;
  padding: 18px 18px;
}

.btn-fix:focus::before {
  opacity: 0 !important;
}

.btn-fix:hover::before {
  opacity: 0.08 !important;
}
</style>
