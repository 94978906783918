import MapboxDraw from "@mapbox/mapbox-gl-draw";
import axios from "axios";
import centerOfMass from "@turf/center-of-mass";

const initMap = (id, bbox, style, options = {}) => {
  const initMap = new Map({
    container: id,
    style: style,
    bounds: bbox,
    fitBoundsOptions: {
      padding: options?.padding == undefined ? 50 : options.padding
    },
    interactive:
      options?.interactive === undefined ? true : options.interactive,
    attributionControl:
      options?.attribution == undefined ? true : options.attribution
  });

  if (options?.control) {
    this.draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true
      }
    });

    initMap.addControl(this.draw, "top-right");
  }

  const mapPromise = new Promise(resolve => {
    initMap.on("load", () => resolve(initMap));
  });

  return { map: initMap, mapPromise };
};

const polygonToCenter = fc => {
  const points = fc.features.map(feature =>
    centerOfMass(feature.geometry, { properties: { ...feature.properties } })
  );

  return {
    type: "FeatureCollection",
    features: points
  };
};

const initS2TileGrid = async map => {
  const response = await axios.get(
    `https://public-geodata.s3.eu-central-1.amazonaws.com/wq-online/tiles/sentinel_2_tiles.geojson`,
    {
      transformRequest: (data, headers) => {
        delete headers["Authorization"];
        return data;
      }
    }
  );
  // console.log(response.data);
  // const convertedFileData = await zipShpToGeojson(response.data);
  // console.log(convertedFileData);
  const s2Grids = response.data;
  const labels = polygonToCenter(s2Grids);

  map.addSource("s2-grid-polygon-source", {
    type: "geojson",
    data: s2Grids
  });

  map.addSource("s2-grid-point-source", {
    type: "geojson",
    data: labels
  });

  map.addLayer({
    id: "s2-tile-grid-layer",
    type: "line",
    source: "s2-grid-polygon-source",
    layout: {
      visibility: "none"
    },
    paint: {
      "line-color": "#2c4186",
      "line-width": 1
    }
  });

  map.addLayer({
    id: "s2-tile-label-layer",
    type: "symbol",
    source: "s2-grid-point-source",
    minzoom: 5,
    layout: {
      "text-font": ["Ubuntu Regular"],
      "text-field": ["get", "Name"],
      "text-size": 24,
      "text-variable-anchor": ["top", "bottom", "left", "right"],
      "text-radial-offset": 0.5,
      "text-justify": "auto",
      visibility: "none"
    },
    paint: {
      "text-color": "#2c4186",
      "text-halo-color": "white",
      "text-halo-width": 1,
      "text-halo-blur": 1
    }
  });
};

const showS2TileGrid = async (map, show) => {
  const gridName = "s2-tile-grid-layer";
  const labelName = "s2-tile-label-layer";
  const visibility = show ? "visible" : "none";
  if (map.getLayer(gridName)) {
    map.setLayoutProperty(gridName, "visibility", visibility);
    map.setLayoutProperty(labelName, "visibility", visibility);
  } else {
    await initS2TileGrid(map);
    map.setLayoutProperty(gridName, "visibility", visibility);
    map.setLayoutProperty(labelName, "visibility", visibility);
  }
};

const initLsatTileGrid = async map => {
  const response = await axios.get(
    `https://public-geodata.s3.eu-central-1.amazonaws.com/wq-online/tiles/landsat_tiles.geojson`,
    {
      transformRequest: (data, headers) => {
        delete headers["Authorization"];
        return data;
      }
    }
  );
  console.log(response.data);
  const lsatGrids = response.data;
  const labels = polygonToCenter(lsatGrids);

  map.addSource("lsat-grid-polygon-source", {
    type: "geojson",
    data: lsatGrids
  });

  map.addSource("lsat-grid-point-source", {
    type: "geojson",
    data: labels
  });

  map.addLayer({
    id: "lsat-tile-grid-layer",
    type: "line",
    source: "lsat-grid-polygon-source",
    layout: {
      visibility: "none"
    },
    paint: {
      "line-color": "#40ADD5",
      "line-width": 1
    }
  });

  map.addLayer({
    id: "lsat-tile-label-layer",
    type: "symbol",
    source: "lsat-grid-point-source",
    minzoom: 5,
    layout: {
      "text-font": ["Ubuntu Regular"],
      "text-field": ["get", "Name"],
      "text-size": 24,
      "text-variable-anchor": ["top", "bottom", "left", "right"],
      "text-radial-offset": 0.5,
      "text-justify": "auto",
      visibility: "none"
    },
    paint: {
      "text-color": "#40ADD5",
      "text-halo-color": "white",
      "text-halo-width": 1,
      "text-halo-blur": 1
    }
  });
};

const showLsatTileGrid = async (map, show) => {
  const gridName = "lsat-tile-grid-layer";
  const labelName = "lsat-tile-label-layer";
  const visibility = show ? "visible" : "none";
  if (map.getLayer(gridName)) {
    map.setLayoutProperty(gridName, "visibility", visibility);
    map.setLayoutProperty(labelName, "visibility", visibility);
  } else {
    await initLsatTileGrid(map);
    map.setLayoutProperty(gridName, "visibility", visibility);
    map.setLayoutProperty(labelName, "visibility", visibility);
  }
};

export default { initMap, showS2TileGrid, showLsatTileGrid };
