<template>
  <v-dialog v-model="dialog" width="auto" max-width="460" style="z-index: 999">
    <v-card class="mx-auto">
      <v-card
        elevation="0"
        class="white--text align-end"
        height="120px"
        style="background-color: #C5D4EB"
      >
        <v-btn class="icon-check" style="background-color: #1D4180" icon fab>
          <v-icon color="white" large>mdi-information-outline</v-icon>
        </v-btn>
      </v-card>
      <v-card-title class="pt-8 justify-center align-center">
        {{ $t("order.sceneSelectionStep.noSceneSelectionTitle") }}
      </v-card-title>

      <v-card-text class="pt-3 text-center align-center">
        <span>{{ $t("order.sceneSelectionStep.noSceneSelectionText") }}</span>
        <br />
        <v-btn text color="secondary" @click="dialog = false"
          >Change Filter</v-btn
        >
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-row
          no-gutters
          class="d-flex flex-row"
          style="background-color: white"
          align="center"
        >
          <v-col cols="auto">
            <v-btn text @click="previousStep">
              <v-icon>mdi-chevron-left</v-icon>
              {{ $t("order.back") }}
            </v-btn>
          </v-col>
          <v-spacer />
          <v-col class="d-flex flex-row-reverse ml-4" cols="auto">
            <v-btn color="primary" rounded @click="nextStep"
              >{{ $t("continue") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "NoHistoryScenesDialog",
  data() {
    return {
      dialog: true
    };
  },
  methods: {
    nextStep() {
      this.$emit("nextStep");
    },
    previousStep() {
      this.$emit("previousStep");
    }
  }
};
</script>

<style scoped>
.icon-check {
  position: absolute;
  top: 90px;
  left: 195px;
}

.icon-text {
  /* v-text/body-small */
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.25px;
}

.icon-symbol {
  font-size: 38px;
}
</style>
