<template>
  <v-container style="background-color: #F6F7FB; ">
    <v-row align="center" class="px-6" style="min-height: 100px">
      <v-col cols="auto">
        <span class="heading-2">{{ step }} - {{ $t(`${title}`) }}</span>
      </v-col>
      <v-col cols="auto">
        <span class="caption-1">{{ $t(`${subTitle}`) }}</span>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "StepHeader",
  props: {
    step: Number,
    title: String,
    subTitle: String
  }
};
</script>

<style scoped></style>
