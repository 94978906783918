<template>
  <v-card class="mt-5 px-3">
    <v-card-subtitle class="body-default">
      <span class="body-default">{{
        $t("order.defineTimeRangeStep.rangeCardDescription")
      }}</span>
    </v-card-subtitle>
    <v-card-text>
      <v-row
        class="my-3 mx-1"
        v-for="(range, index) in timeranges"
        :key="index"
        style="background-color: #F6F7FB;"
      >
        <v-col>
          <v-menu
            v-model="startMenu[index]"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-row>
                <v-col cols="auto" v-bind="attrs" v-on="on" class="pt-4">
                  <v-btn class="pb-2" icon>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                  <span class="body-default">
                    {{ $t("orderRequest.startDate") }}</span
                  >
                  <span
                    v-if="range.startDate"
                    class=" ml-5 mt-6 heading-4-bold"
                    :style="{ color: range.tag == 'error' ? 'red' : '' }"
                    >{{ range.startDate }}</span
                  >
                  <span
                    v-else
                    class="ml-5 heading-4-bold"
                    style="color: #8996AB"
                    >YYYY-MM-DD</span
                  >
                </v-col>
              </v-row>
            </template>
            <v-date-picker
              :min="'2013-01-01'"
              v-model="range.startDate"
              @input="startMenu[index] = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col>
          <v-menu
            v-model="endMenu[index]"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-row>
                <v-col cols="auto" v-bind="attrs" v-on="on" class="pt-4">
                  <v-btn icon class="pb-2">
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                  <span class="body-default">
                    {{ $t("orderRequest.endDate") }}</span
                  >
                  <span
                    v-if="range.endDate"
                    class=" ml-5 heading-4-bold"
                    :style="{ color: range.tag == 'error' ? 'red' : '' }"
                    >{{ range.endDate }}</span
                  >
                  <span
                    v-else
                    class="ml-5 heading-4-bold"
                    style="color: #8996AB"
                    >YYYY-MM-DD</span
                  >
                </v-col>
              </v-row>
            </template>
            <v-date-picker
              :max="endDate"
              v-model="range.endDate"
              @input="endMenu[index] = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <!--        <v-col class="align-end" cols="auto">-->
        <!--          <v-btn icon @click="timeranges.splice(index, 1)"-->
        <!--            ><v-icon :color="'#8996AB'">mdi-delete-outline</v-icon></v-btn-->
        <!--          >-->
        <!--        </v-col>-->
      </v-row>
      <v-row class="pt-3 " v-if="timeranges.length < 1">
        <v-col>
          <v-btn
            class="add-button text-lowercase"
            color="#536380"
            outlined
            width="100%"
            height="60px"
            @click="
              timeranges.push({});
              startMenu.push(false);
              endMenu.push(false);
            "
          >
            <span class="heading-5">{{
              $t("order.defineTimeRangeStep.addTimerange")
            }}</span></v-btn
          >
        </v-col></v-row
      >
    </v-card-text>
  </v-card>
</template>
<script>
import moment from "moment";

export default {
  name: "RangeCard",
  props: {
    timeranges: {
      type: Array,
      default: () => {
        [];
      }
    }
  },
  data: () => ({
    startMenu: [false],
    endMenu: [false],
    error: false
  }),
  computed: {
    endDate() {
      const today = moment().format("YYYY-MM-DD");
      return moment(today)
        .add(2, "y")
        .format("YYYY-MM-DD");
    }
  },
  methods: {
    checkOrder(range) {
      if (range.endDate && range.startDate && range.endDate < range.startDate) {
        this.$store.dispatch("app/showSnackbar", {
          show: true,
          message: this.$t("order.defineTimeRangeStep.checkInputs"),
          color: "error"
        });
        range.tag = "error";
      } else {
        range.tag = "ok";
      }
    }
  },

  watch: {
    timeranges: {
      handler() {
        this.timeranges.forEach(range => {
          this.checkOrder(range);
        });
        this.$emit("updateTimeranges", this.timeranges);
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.add-button {
  border: 1px dashed #8996ab;
  border-radius: 3px;
}
</style>
