<template>
  <v-container class="d-flex align-center">
    <v-row
      style="align-items: center"
      v-if="
        !filteredScenes.length &&
          !possibleScenes.length &&
          helper.historicRanges.length
      "
    >
      <v-col align="center">
        <v-card
          elevation="0"
          style="background-color: #E7E6EC; outline-style: dashed; outline-color: grey"
          class="rounded-circle text-center d-flex flex-column align-center justify-center"
          width="20vh"
          height="20vh"
          rounded
        >
          <v-icon size="15vh">mdi-magnify</v-icon>
        </v-card>
      </v-col>
      <v-col>
        <v-row class="pt-5 font-weight-light heading-4">
          {{ $t("order.sceneSelectionStep.noScenesFound") }}
        </v-row>
        <v-row class="pt-5 ">
          {{ $t("order.sceneSelectionStep.changeDateRange") }}
        </v-row>
        <v-row class="pt-10"> </v-row>
      </v-col>
    </v-row>
    <v-row
      style="align-items: center"
      v-if="
        !filteredScenes.length &&
          possibleScenes.length &&
          helper.historicRanges.length
      "
    >
      <v-col align="center">
        <v-card
          elevation="0"
          style="background-color: #E7E6EC; outline-style: dashed; outline-color: grey"
          class="rounded-circle text-center d-flex flex-column align-center justify-center"
          width="20vh"
          height="20vh"
          rounded
        >
          <v-icon size="15vh">mdi-magnify-remove-outline</v-icon>
        </v-card>
      </v-col>
      <v-col>
        <v-row class="pt-5 font-weight-light heading-4">
          {{ $t("order.sceneSelectionStep.noResultsFound") }}
        </v-row>
        <v-row class="pt-5 ">
          {{ $t("order.sceneSelectionStep.noResultsDescription") }}
        </v-row>
        <v-row class="pt-10">
          {{ $t("order.sceneSelectionStep.changeFilterSettings") }}
        </v-row>
      </v-col>
    </v-row>
    <v-row style="align-items: center" v-if="!helper.historicRanges.length">
      <v-col align="center">
        <v-card
          elevation="0"
          style="background-color: #E7E6EC; outline-style: dashed; outline-color: grey"
          class="rounded-circle text-center d-flex flex-column align-center justify-center"
          width="20vh"
          height="20vh"
          rounded
        >
          <v-icon size="15vh">mdi-magnify-remove-outline</v-icon>
        </v-card>
      </v-col>
      <v-col>
        <v-row class="pt-5 ">
          {{ $t("order.sceneSelectionStep.noSceneSelectionText") }}
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "EmptyStates",
  props: {
    filteredScenes: Array,
    helper: Object,
    possibleScenes: Array
  }
};
</script>

<style scoped></style>
