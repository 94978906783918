<template>
  <v-card max-height="60vh" class="d-flex flex-column" min-height="58vh">
    <div id="view-map" style="height: 200px; z-index: 3"></div>
    <v-card-subtitle class="font-weight-bold" style="font-size: 20px">
      {{ order.regionName }}
    </v-card-subtitle>
    <v-card-text class="grow ">
      <v-row v-if="helper.timeranges.length">
        <v-col cols="4"> {{ $t("order.viewAndPayStep.timeranges") }}: </v-col>
        <v-col cols="auto">
          <v-row
            class="ma-1"
            style="font-weight: bold"
            v-for="range in helper.timeranges"
            :key="range.startDate"
          >
            {{ range.startDate }} -> {{ range.endDate }}
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4"> {{ $t("order.products") }}: </v-col>
        <v-col
          cols="auto"
          class="overflow-y-auto custom-scrollbar"
          style="max-height: 130px"
        >
          <v-row
            class="ma-1"
            style="font-weight: bold"
            v-for="(product, index) in order.products"
            :key="product"
          >
            <span v-if="index <= maxProducts">{{
              $t(`products.` + product)
            }}</span>
          </v-row>
          <v-row v-if="order.products.length > 3">
            <v-btn
              v-if="maxProducts == 2"
              text
              dense
              class="py-0 my-0"
              @click="maxProducts = order.products.length"
              >show all</v-btn
            >
            <v-btn v-else text dense class="py-0 my-0" @click="maxProducts = 2"
              >reduce</v-btn
            ></v-row
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4"> {{ $t("order.resolutions") }}: </v-col>
        <v-col style="font-weight: bold">
          <v-row
            class="ma-1"
            v-for="resolution in Array.from(helper.resolutions)"
            :key="resolution"
          >
            {{ $t(`order.resolutionStep.${resolution}`) }} ({{
              resolutionDict[resolution]
            }})
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4"> {{ $t("order.viewAndPayStep.scenesCount") }}: </v-col>
        <v-col class="ma-1" cols="auto" style="font-weight: bold">
          {{ order.selectedScenes.length }}
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4"> {{ $t("order.viewAndPayStep.plan") }}: </v-col>
        <v-col class="ma-1" cols="auto">
          <v-row
            class="ma-1"
            style="font-weight: bold"
            v-for="service in order.services"
            :key="service"
          >
            {{ getServiceName(service) }}
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="isAdmin">
        <v-col cols="4 mt-2">
          {{ $t("order.viewAndPayStep.configFile") }}:
        </v-col>
        <v-col class="ml-1" cols="auto">
          <v-text-field
            placeholder="default"
            outlined
            dense
            v-model="order.mission_config"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="isAdmin">
        <v-col cols="4 mt-2">
          {{ $t("order.viewAndPayStep.mipVersion") }}:
        </v-col>
        <v-col class="ml-1" cols="auto">
          <v-text-field
            placeholder="default"
            outlined
            dense
            v-model="order.mip_version"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import MapboxConfig from "@/core/plugins/mapbox";
import bbox from "@turf/bbox";
import * as Llet from "leaflet";

export default {
  name: "overview",
  props: {
    helper: Object,
    order: Object,
    loading: Boolean
  },
  data() {
    return {
      map: null,
      draw: null,
      numberOfRegions: 0,
      maxProducts: 2,
      resolutionDict: {
        fine: "Sentinel-2, Landsat 8/9",
        coarse: "Sentinel-3",
        planet: "Planet SuperDove"
      },
      maploading: true,
      services: [
        {
          id: "premium",
          name: "Premium"
        },
        {
          id: "standard",
          name: "Standard"
        }
      ]
    };
  },
  computed: {
    months() {
      const monthsDict = [
        { val: "1", label: "month.jan" },
        { val: "2", label: "month.feb" },
        { val: "3", label: "month.mar" },
        { val: "4", label: "month.apr" },
        { val: "5", label: "month.mai" },
        { val: "6", label: "month.jun" },
        { val: "7", label: "month.jul" },
        { val: "8", label: "month.aug" },
        { val: "9", label: "month.sep" },
        { val: "10", label: "month.oct" },
        { val: "11", label: "month.nov" },
        { val: "12", label: "month.dec" }
      ];
      return monthsDict;
    },
    isAdmin() {
      const client = this.$appConfig.keycloakClient;
      const roles = this.$keycloak.resourceAccess?.[client]?.roles;
      return roles && roles.includes("admin");
    }
  },

  methods: {
    initMap(geojson) {
      const aoiBbox = bbox(geojson);
      const padding = 0.01;
      const initMap = Llet.map("view-map", {
        attributionControl: false,
        zoomControl: false
      }).fitBounds([
        [aoiBbox[1] - padding, aoiBbox[0] - padding],
        [aoiBbox[3] + padding, aoiBbox[2] + padding]
      ]);

      this.disableMapInteraction(initMap, "view-map", "cursor");

      Llet.tileLayer(
        `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=${MapboxConfig.accessToken}`
      ).addTo(initMap);

      const geojsonLayer = Llet.geoJSON(geojson, {
        style: {
          color: "#40ADD5",
          weight: 3
        }
      }).addTo(initMap);
      geojsonLayer.on("mouseover", function(event) {
        event.layer.getElement().style.cursor = "auto";
      });
      return initMap;
    },
    disableMapInteraction(map, id, cursor) {
      map.dragging.disable();
      map.touchZoom.disable();
      map.doubleClickZoom.disable();
      map.scrollWheelZoom.disable();
      map.boxZoom.disable();
      map.keyboard.disable();
      if (map.tap) map.tap.disable();
      document.getElementById(id).style.cursor = cursor;
    },
    addGeoJsonToSelection(feature) {
      this.numberOfRegions += 1;
      this.draw.add(feature);
      // const selectionGeojson = this.draw.getAll();c
      // this.zoomToBBox(selectionGeojson);
    },
    getServiceName(id) {
      const selectedService = this.services.filter(
        service => service.id === id
      );
      return selectedService[0].name;
    }
  },
  watch: {
    // "order.expertEye"() {
    //   this.$emit("updateOrder", this.order);
    // },
    "order.mission_config"() {
      this.$emit("updateOrder", this.order);
    },
    "order.mip_version"() {
      this.$emit("updateOrder", this.order);
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.order.geojson.features) {
        this.initMap(this.order.geojson);
        this.maploading = false;
      }
    });
  }
};
</script>

<style scoped>
.custom-scrollbar ::-webkit-scrollbar {
  width: 10px;
}

.custom-scrollbar ::-webkit-scrollbar-thumb {
  background-color: #e9eff5;
  border-radius: 8px;
}
</style>
