<template>
  <div class="d-flex flex-column full-height">
    <div class="flex-grow-0">
      <order-stepper v-model="step" />
      <step-header
        :step="step"
        :title="'order.viewAndPayStep.overviewAndPay'"
        :sub-title="'order.viewAndPayStep.overviewAndPayDescription'"
      ></step-header>
    </div>
    <v-row class=" d-flex flex-column ">
      <v-col class="d-flex grow overflow-y-auto">
        <v-container class="px-10">
          <v-row>
            <v-col cols="6">
              <account-cards
                v-if="!loading"
                @setAccount="setAccount"
                :order="order"
                :pricesum="priceSum"
              ></account-cards>
              <terms-and-conditions @termsAndConditionsStatus="changeTandC">
              </terms-and-conditions>
            </v-col>
            <v-col cols="6">
              <overview
                v-if="!loading"
                :loading="priceLoading"
                :order="order"
                :price="priceSum"
                :helper="helper"
                @updateOrder="updateOrder"
              ></overview>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col class="d-flex shrink ">
        <v-row
          no-gutters
          class="pa-5"
          style="background-color: white"
          align="center"
        >
          <v-col cols="auto">
            <v-btn text @click="previousStep">
              <v-icon>mdi-chevron-left</v-icon>
              {{ $t("order.back") }}
            </v-btn>
          </v-col>
          <v-col class="d-flex flex-row-reverse" cols="auto">
            <v-btn color="secondary" text @click="cancel"
              >{{ $t("cancel") }}
            </v-btn>
          </v-col>
          <v-spacer />
          <v-col cols="auto" class="-flex flex-row-reverse mr-16 ml-2">
            <h2 class="primary--text">
              <span class="font-weight-light">
                {{ priceSum }}
                Credits
              </span>
            </h2>
          </v-col>
          <v-col class="d-flex flex-row-reverse ml-4" cols="auto">
            <v-btn
              color="primary"
              rounded
              @click="submitOrderRequest"
              :disabled="acceptedTandC || clickSend || selectedAccount === null"
              >{{ $t("order.viewAndPayStep.submit") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <success-dialog v-if="successDialog"></success-dialog>
  </div>
</template>

<script>
import OrderStepper from "@/core/components/order/OrderStepper";
import axios from "axios";
import Vue from "vue";
import bbox from "@turf/bbox";
import bboxPolygon from "@turf/bbox-polygon";
import buffer from "@turf/buffer";
import creditMixin from "@/core/mixins/credit.mixin";
import Overview from "@/core/components/order/steps/viewAndPay/overview.vue";
import AccountCards from "@/core/components/order/steps/viewAndPay/accountCards.vue";
import StepHeader from "@/core/components/order/StepHeader.vue";
import SuccessDialog from "@/core/components/order/steps/viewAndPay/successDialog.vue";
import priceCalculationMixin from "@/core/mixins/priceCalculation.mixin";
import TermsAndConditions from "@/core/components/order/steps/viewAndPay/TermsAndConditions.vue";
export default {
  name: "ViewAndPay",
  mixins: [creditMixin, priceCalculationMixin],
  components: {
    SuccessDialog,
    StepHeader,
    AccountCards,
    Overview,
    OrderStepper,
    TermsAndConditions
  },
  props: {
    value: Number,
    order: Object,
    helper: Object
  },
  data() {
    return {
      step: this.value,
      clickSend: false,
      creditObject: [],
      possibleAccounts: [],
      selectedAccount: null,
      successDialog: false,
      priceLoading: false,
      loading: true,
      priceSum: 0,
      acceptedTandC: true
    };
  },
  computed: {
    regionId() {
      if (this.$route?.params.regionId) {
        return this.$route.params.regionId;
      }
      return null;
    }
  },
  methods: {
    previousStep() {
      this.$emit("updateOrder", this.order);
      this.$emit("input", this.step - 1);
    },
    cancel() {
      this.$router.push({ name: "Regions" });
    },
    setAccount(value) {
      this.selectedAccount = value;
    },
    async updateOrder(value) {
      this.order = value;
    },

    async updateHelper(value) {
      this.helper = value;
    },

    async createRegion() {
      const bboxPolygonFeature = buffer(
        bboxPolygon(bbox(this.order.geojson)),
        5
      );
      const client = Vue.prototype.$appConfig.keycloakClient;
      const response = await axios.post(`/management/${client}/regions/`, {
        name: this.order.regionName,
        description: this.order.regionDescription,
        aoi: {
          type: "FeatureCollection",
          features: [bboxPolygonFeature]
        }
      });
      return response.data.id;
    },

    async orderData(regionId) {
      let numberScenes = this.order.selectedScenes.length;
      if (this.helper.futureRanges.length) {
        const numberFutureScenes = this.getSceneNumber(
          this.helper.futureRanges[0].startDate,
          this.helper.futureRanges[0].endDate
        );
        numberScenes = numberFutureScenes + this.order.selectedScenes.length;
      }
      if (!numberScenes) {
        numberScenes = 0;
      }
      const data = {
        order_name: this.order.regionName,
        products: this.order.products,
        aoi: this.order.geojson,
        scenes: this.order.selectedScenes,
        sensors: Array.from(this.order.sensors),
        region: regionId,
        credit_account_id: this.selectedAccount,
        credit_comment: "",
        number_scenes: numberScenes,
        expert_eye: this.order.expertEye,
        number_of_aois: this.order.geojson.features.length,
        start_date: this.order.timerange.startDate,
        end_date: this.order.timerange.endDate,
        max_cloud_cover: this.order.cloudCover
      };
      if (this.order?.mission_config) {
        data.mission_configuration = this.order.mission_config;
      }
      if (this.order?.mip_version) {
        data.mip_version = this.order.mip_version;
      }
      if (this.order.services.includes("premium")) {
        data.services = this.order.services;
      }
      const response = await axios.post(`/orders/`, data);
      console.log(response);
    },
    async submitOrderRequest() {
      this.clickSend = true;
      if (this.regionId == null) {
        const regionId = await this.createRegion();
        await this.orderData(regionId);
      } else {
        await this.orderData(this.regionId);
      }
      this.successDialog = true;
    },
    calcPriceSum() {
      this.priceLoading = true;
      let priceSum = 0;
      if (this.helper.scenePriceFuture) {
        priceSum = priceSum + this.helper.scenePriceFuture;
      }
      if (this.helper.scenePriceHistoric) {
        priceSum = priceSum + this.helper.scenePriceHistoric;
      }
      const scenePrice = priceSum;
      priceSum = priceSum + scenePrice * 0.3;
      priceSum = Math.ceil(priceSum);
      if (this.order.services.includes("premium")) {
        if (scenePrice * 0.1 < 11) {
          priceSum = priceSum + 11;
        } else {
          priceSum = priceSum + scenePrice * 0.1;
        }
      }
      priceSum = Math.ceil(priceSum);
      this.priceLoading = false;
      this.priceSum = priceSum;
      this.order.price = priceSum;
    },
    changeTandC(value) {
      this.acceptedTandC = !value;
    }
  },
  async created() {
    this.creditObject = await this.fetchUserAccounts();
    this.calcPriceSum();
    this.loading = false;
  }
};
</script>

<style scoped></style>
