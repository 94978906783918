<template>
  <div class="pt-5 mt-10">
    <span class="heading-4-bold">
      {{ $t("order.termsAndConditions.terms") }}
    </span>
    <v-card class="my-5">
      <v-card-text>
        <div class="mt-4 mx-2">
          <v-checkbox
            color="primary"
            v-model="acceptInvoice"
            :rules="[agreeRule]"
            required
            density="compact"
            :label="$t('order.termsAndConditions.acceptInvoice')"
          />
          <v-checkbox
            v-model="acceptEula"
            color="primary"
            required
            :rules="[agreeRule]"
            density="compact"
          >
            <template v-slot:label>
              <div>
                {{ $t("order.termsAndConditions.acceptEula") }}
                <a
                  target="_blank"
                  href="https://www.eomap.com/exchange/pdf/EOMAP_Terms_Conditions_for_User_of_EOMAP_Products.pdf"
                  @click.stop
                >
                  EULA
                </a>
              </div>
            </template>
          </v-checkbox>
          <v-checkbox
            v-model="acceptDisclaimer"
            color="primary"
            density="compact"
            :label="$t('order.termsAndConditions.acceptDisclaimer')"
            required
            :rules="[agreeRule]"
          >
            <template v-slot:label>
              <div>
                {{ $t("order.termsAndConditions.acceptEula") }}
                <a
                  @click="showDisclaimer = !showDisclaimer"
                  class="text-decoration-underline"
                >
                  Disclaimer
                </a>
              </div>
            </template>
          </v-checkbox>
          <p class="pl-10 text-body-2" v-if="showDisclaimer">
            {{ $t("order.termsAndConditions.disclaimer") }}
            {{ $t("order.termsAndConditions.moreInfoDisclaimer") }}

            <a href="mailto:wq@eomap.de">wq@eomap.de</a>
          </p>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "TermsAndConditions",
  data() {
    return {
      acceptInvoice: false,
      acceptEula: false,
      acceptDisclaimer: false,
      showDisclaimer: false
    };
  },
  computed: {
    allChecked() {
      return this.acceptInvoice && this.acceptEula && this.acceptDisclaimer;
    }
  },
  methods: {
    agreeRule(value) {
      if (!value) {
        return this.$t("order.termsAndConditions.agreeRule");
      } else {
        return true;
      }
    }
  },
  watch: {
    allChecked(newValue) {
      this.$emit("termsAndConditionsStatus", newValue);
    }
  }
};
</script>

<style scoped></style>
