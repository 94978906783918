<template>
  <div class="d-flex flex-column full-height">
    <div class="flex-grow-0">
      <order-stepper v-model="step" />
      <step-header
        :step="step"
        :title="'order.servicesChoiceStep.chooseServices'"
        :sub-title="'order.servicesChoiceStep.chooseServicesDescription'"
      ></step-header>
    </div>
    <v-row class=" fill-height d-flex flex-column">
      <v-col class="d-flex grow overflow-y-auto">
        <v-container class="pl-10">
          <v-row>
            <v-col class="d-flex justify-end">
              <v-card
                width="500px"
                outlined
                :style="{
                  'border-color': selectedPlan === 'premium' ? '' : '#2c4186'
                }"
                :disabled="disabledServices.includes('premium')"
              >
                <v-card-title
                  class="plan-title"
                  :style="{
                    background:
                      selectedPlan === 'standard' ? '#E9EFF5' : '#f6f9f9'
                  }"
                >
                  <span class="heading-3">
                    {{ $t("order.servicesChoiceStep.standardPlan") }}</span
                  >
                  <v-spacer />
                  <v-btn
                    v-if="selectedPlan === 'standard'"
                    color="primary"
                    rounded
                  >
                    {{ $t("order.servicesChoiceStep.selected") }}</v-btn
                  >
                  <v-btn
                    v-else
                    @click="selectedPlan = 'standard'"
                    color="secondary"
                    rounded
                  >
                    {{ $t("order.servicesChoiceStep.select") }}</v-btn
                  >
                </v-card-title>
                <v-card-text class="mb-0">
                  <v-row
                    class="plan-section"
                    v-for="row in plans"
                    :key="row.title"
                  >
                    <v-col class="px-0 mx-0 col-section">
                      <v-icon :color="row.standard ? '#173466' : '#8996AB'">
                        {{ row.icon }}</v-icon
                      >
                      <span
                        :style="{ color: row.standard ? '#173466' : '#8996AB' }"
                        >{{ $t(`${row.title}`) }}</span
                      >
                      <v-spacer />
                      <v-icon color="green" v-if="row.standard"
                        >mdi-check-circle-outline</v-icon
                      >
                      <v-icon v-else>mdi-minus</v-icon>
                    </v-col>
                  </v-row></v-card-text
                >
                <v-card-actions class="justify-end">+ 0 Credits</v-card-actions>
              </v-card>
            </v-col>
            <v-col class="d-flex justify-start">
              <v-card
                width="500px"
                outlined
                :style="{
                  'border-color': selectedPlan === 'premium' ? '#2c4186' : ''
                }"
                :disabled="disabledServices.includes('premium')"
              >
                <v-card-title
                  class="plan-title"
                  :style="{
                    background:
                      selectedPlan === 'premium' ? '#E9EFF5' : '#f6f9f9'
                  }"
                >
                  <span class="heading-3">
                    {{ $t("order.servicesChoiceStep.premiumPlan") }}</span
                  >
                  <v-spacer />
                  <v-btn
                    v-if="selectedPlan === 'premium'"
                    color="primary"
                    rounded
                  >
                    {{ $t("order.servicesChoiceStep.selected") }}</v-btn
                  >
                  <v-btn
                    v-else
                    @click="selectedPlan = 'premium'"
                    color="secondary"
                    rounded
                  >
                    {{ $t("order.servicesChoiceStep.select") }}</v-btn
                  >
                </v-card-title>
                <v-card-text class="mb-0">
                  <v-row
                    class="plan-section"
                    v-for="row in plans"
                    :key="row.title"
                  >
                    <v-col class="px-0 mx-0 col-section">
                      <v-icon :color="row.premium ? '#173466' : '#8996AB'">
                        {{ row.icon }}</v-icon
                      >
                      <span
                        :style="{ color: row.premium ? '#173466' : '#8996AB' }"
                        >{{ $t(`${row.title}`) }}</span
                      >
                      <v-spacer />
                      <v-icon color="green" v-if="row.premium"
                        >mdi-check-circle-outline</v-icon
                      >
                      <v-icon v-else>mdi-minus</v-icon>
                    </v-col>
                  </v-row></v-card-text
                >
                <v-card-actions class="justify-end"
                  >+
                  {{ planPrice.toFixed(0) }}
                  Credits</v-card-actions
                >
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col class="d-flex shrink">
        <v-row
          no-gutters
          class="pa-5"
          style="background-color: white"
          align="center"
        >
          <v-col cols="auto">
            <v-btn text @click="previousStep">
              <v-icon>mdi-chevron-left</v-icon>
              {{ $t("order.back") }}
            </v-btn>
          </v-col>
          <v-col class="d-flex flex-row-reverse" cols="auto">
            <v-btn color="secondary" text @click="cancel"
              >{{ $t("cancel") }}
            </v-btn>
          </v-col>

          <v-spacer />
          <v-col cols="auto" class="-flex flex-row-reverse mr-16 ml-2">
            <h2 class="primary--text">
              <span class="font-weight-light">
                {{ priceSum }}
                Credits
              </span>
            </h2>
          </v-col>
          <v-col class="d-flex flex-row-reverse ml-4" cols="auto">
            <v-btn color="primary" rounded @click="nextStep"
              >{{ $t("continue") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import OrderStepper from "@/core/components/order/OrderStepper";
import StepHeader from "@/core/components/order/StepHeader.vue";
import { black } from "vue-plotly/dist/vue-plotly.common";

export default {
  name: "ServicesChoiceStep",
  components: { StepHeader, OrderStepper },
  props: {
    value: Number,
    order: Object,
    helper: Object,
    regionDetails: Object
  },
  data() {
    return {
      step: this.value,
      servicesPrice: 0,
      disabledServices: [],
      selectedPlan: "standard",
      plans: [
        {
          icon: "mdi-satellite-variant",
          title: "order.servicesChoiceStep.displayEODate",
          standard: true,
          premium: true
        },
        {
          icon: "mdi-file-upload-outline",
          title: "order.servicesChoiceStep.uploadExternalData",
          standard: true,
          premium: true
        },
        {
          icon: "mdi-vector-polygon",
          title: "order.servicesChoiceStep.defineVS",
          standard: true,
          premium: true
        },
        {
          icon: "mdi-chart-timeline",
          title: "order.servicesChoiceStep.extractTimeSeries",
          standard: true,
          premium: true
        },
        {
          icon: "mdi-history",
          title: "order.servicesChoiceStep.histData",
          standard: true,
          premium: true
        },
        {
          icon: "mdi-chart-timeline-variant-shimmer",
          title: "order.servicesChoiceStep.monitoring",
          standard: true,
          premium: true
        },
        {
          icon: "mdi-poll",
          title: "order.servicesChoiceStep.statistics",
          standard: false,
          premium: true
        },
        {
          icon: "mdi-compare",
          title: "order.servicesChoiceStep.compareSites",
          standard: false,
          premium: true
        },
        {
          icon: "mdi-view-dashboard",
          title: "order.servicesChoiceStep.alertDashboard",
          standard: false,
          premium: true
        },
        {
          icon: "mdi-chart-line",
          title: "order.servicesChoiceStep.monitoringDashboard",
          standard: false,
          premium: true
        },
        {
          icon: "mdi-bell-outline",
          title: "order.servicesChoiceStep.defineThresholds",
          standard: false,
          premium: true
        },
        {
          icon: "mdi-email-outline",
          title: "order.servicesChoiceStep.emailNotification",
          standard: false,
          premium: true
        }
      ],
      priceSum: 0
    };
  },
  computed: {
    planPrice() {
      const price =
        this.helper.scenePriceFuture + this.helper.scenePriceHistoric;
      if (price * 0.1 < 11) {
        return 11;
      } else {
        return price * 0.1;
      }
    }
  },
  methods: {
    black() {
      return black;
    },
    nextStep() {
      this.order.services = [this.selectedPlan];
      this.$emit("updateOrder", this.order);
      this.$emit("input", this.step + 1);
    },
    previousStep() {
      this.order.services = [this.selectedPlan];
      this.$emit("updateOrder", this.order);
      this.$emit("input", this.step - 1);
    },
    cancel() {
      this.$router.push({ name: "Regions" });
    },
    calServicePrice() {
      this.helper.servicePrice = 0;
    },
    calcPriceSum() {
      this.priceLoading = true;
      let priceSum = 0;
      if (this.helper.scenePriceFuture) {
        priceSum = priceSum + this.helper.scenePriceFuture;
      }
      if (this.helper.scenePriceHistoric) {
        priceSum = priceSum + this.helper.scenePriceHistoric;
      }
      const scenePrice = priceSum;
      priceSum = priceSum + scenePrice * 0.3;
      priceSum = Math.ceil(priceSum);
      if (this.order.services.includes("premium")) {
        if (scenePrice * 0.1 < 11) {
          priceSum = priceSum + 11;
        } else {
          priceSum = priceSum + scenePrice * 0.1;
        }
      }
      priceSum = Math.ceil(priceSum);
      this.priceLoading = false;
      this.priceSum = priceSum;
      this.order.price = priceSum;
    }
  },

  watch: {
    selectedPlan() {
      this.order.services = [this.selectedPlan];
      this.calServicePrice();
      this.calcPriceSum();
    }
  },
  created() {
    if (this.$route.params.regionId) {
      this.disabledServices = this.disabledServices.concat(
        this.regionDetails.properties.services
      );
    }
    if (this.order.services.includes("premium")) {
      this.selectedPlan = "premium";
    }
    this.calServicePrice();
    this.calcPriceSum();
  }
};
</script>

<style scoped>
.plan-title {
  height: 76px;

  /* custom/offwhite */
  /* custom/gray01 */
  border-bottom: 1px solid #e9eff5;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
.plan-section {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  gap: 12px;

  /* custom/gray01 */
  border-bottom: 1px solid #e9eff5;

  /* Inside auto layout */
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
}
.col-section {
  display: flex;
  align-items: center;
  gap: 12px;
  padding-bottom: 0px;

  /* Inside auto layout */
}
</style>
