<template>
  <v-dialog v-model="dialog" width="auto" max-width="700" style="z-index: 999">
    <v-card class="mx-auto">
      <v-card
        elevation="0"
        class="white--text align-end"
        height="120px"
        style="background-color: #C5D4EB"
      >
        <v-btn class="icon-check" style="background-color: #1D4180" icon fab>
          <v-icon color="white" large>mdi-information-outline</v-icon>
        </v-btn>
      </v-card>
      <v-card-title class="pt-8 justify-center align-center"
        ><span class="center">
          {{ $t("order.createRegionStep.welcomeToOrder") }}
        </span>
      </v-card-title>

      <v-card-text class="pt-3 text-center align-center">
        <span class="font-weight-bold">{{
          $t("order.createRegionStep.usecase")
        }}</span>
        <br />
        {{ $t("order.createRegionStep.usecase_desc") }}
        <br />
        <br />
        {{ $t("order.createRegionStep.contactUs") }}
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-spacer />
        <contact-popup
          class="mr-5"
          :userInfo="userInfo"
          :type="'dialogButton'"
        ></contact-popup>
        <v-btn color="primary" text @click="dialog = false">
          {{ $t("order.createRegionStep.tryItOut") }}
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import contactPopup from "@/core/components/bar/contactPopup.vue";
import creditMixin from "@/core/mixins/credit.mixin";

export default {
  name: "NoCreditAccountDialog",
  components: { contactPopup },
  mixins: [creditMixin],
  props: {
    region: {
      type: Object
    },
    place: { type: String, default: () => "" }
  },
  data: function() {
    return {
      dialog: false
    };
  },
  computed: {
    userInfo() {
      const info = [this.$keycloak.userName, this.$keycloak.fullName];
      return info;
    }
  },
  methods: {
    async checkCredits() {
      const accounts = await this.fetchUserAccounts();
      const accountCredits = accounts.filter(account => account.balance > 0);
      if (accountCredits.length == 0) {
        this.dialog = true;
      } else {
        this.dialog = false;
      }
    }
  },
  created() {
    this.checkCredits();
  }
};
</script>

<style scoped>
#close-btn {
  z-index: 1;
}
.icon-check {
  position: absolute;
  top: 90px;
  left: 322px;
}

.icon-text {
  /* v-text/body-small */
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.25px;
}

.icon-symbol {
  font-size: 38px;
}
</style>
