<template>
  <v-dialog v-model="dialog" width="auto" max-width="455" style="z-index: 999">
    <v-card class="mx-auto">
      <v-card
        elevation="0"
        class="white--text align-end"
        height="120px"
        style="background-color: #C5D4EB"
      >
        <v-btn class="icon-check" style="background-color: #1D4180" icon fab>
          <v-icon color="white" large>mdi-information-outline</v-icon>
        </v-btn>
      </v-card>
      <v-card-title class="pt-8 justify-center align-center"
        ><span class="center">
          {{ $t("order.defineAoiStep.shallowWater") }}
        </span>
      </v-card-title>

      <v-card-text class="pt-3 text-center align-center">
        {{ $t("order.defineAoiStep.shallowWaterText") }}
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false">
          {{ $t("understand") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Vue from "vue";

export default {
  name: "ShallowWaterWarning",
  props: {
    region: {
      type: Object
    },
    place: { type: String, default: () => "" }
  },
  data: function() {
    return {
      dialog: true
    };
  },
  computed: {
    isEomapUser() {
      const hasEomapRole = Vue.prototype.$keycloak.realmAccess?.roles.includes(
        "eomap"
      );
      return hasEomapRole;
    }
  },
  created() {
    if (this.isEomapUser) {
      this.dialog = false;
    } else {
      this.dialog = true;
    }
  }
};
</script>

<style scoped>
#close-btn {
  z-index: 1;
}
.icon-check {
  position: absolute;
  top: 90px;
  left: 195px;
}

.icon-text {
  /* v-text/body-small */
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.25px;
}

.icon-symbol {
  font-size: 38px;
}
</style>
