<template>
  <v-container class="d-flex align-center">
    <v-row style="align-items: center">
      <v-col align="center">
        <v-card
          elevation="0"
          style="background-color: #E7E6EC; outline-style: dashed; outline-color: grey"
          class="rounded-circle text-center d-flex flex-column align-center justify-center"
          width="20vh"
          height="20vh"
          rounded
        >
          <v-icon size="15vh">mdi-close-box-outline</v-icon>
        </v-card>
      </v-col>
      <v-col>
        <v-row class="pt-5 font-weight-light heading-4">
          {{ $t("order.sceneSelectionStep.errorInSearch") }}
        </v-row>
        <v-row class="pt-5 ">
          {{ $t("order.sceneSelectionStep.changeAoi") }}
        </v-row>
        <v-row class="pt-10"> </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "errorSceneSearch"
};
</script>

<style scoped></style>
