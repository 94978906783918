<template>
  <v-container fluid class="fill-height py-0">
    <v-row class="fill-height d-flex flex-column">
      <v-col class="d-flex shrink">
        <div class="flex-grow-0">
          <order-stepper v-model="step" />
          <step-header
            :step="step"
            :title="'order.resolutionStep.chooseResolution'"
            :sub-title="'order.resolutionStep.chooseResolutionDescription'"
          ></step-header>
        </div>
      </v-col>
      <v-col class="d-flex grow overflow-y-auto">
        <v-container class="py-4">
          <v-row justify="center">
            <v-col
              v-for="resolution in allowedResolutions"
              :key="resolution.id"
              cols="4"
            >
              <v-card
                :class="{ disabled: disableCard(resolution.id) }"
                elevation="4"
                height="380px"
              >
                <v-img :src="resolution.image" height="120px"></v-img>
                <v-card-title>
                  {{ resolution.name }}
                  <v-spacer />
                  <v-switch
                    v-model="helper.resolutions"
                    :value="resolution.id"
                  />
                </v-card-title>
                <v-card-subtitle>
                  {{ $t("order.resolutionStep.imageFrequency") }}:
                  {{ resolution.imageFrequency }}<br />
                  {{ $t("order.resolutionStep.resolution") }}:
                  {{ resolution.resolution }}<br />
                  {{ $t("order.resolutionStep.sensors") }}:
                  {{ resolution.sensors }}
                </v-card-subtitle>
                <v-card-text>
                  {{ resolution.description }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col class="d-flex shrink pa-0">
        <v-row
          no-gutters
          class="pa-5"
          style="background-color: white"
          align="center"
        >
          <v-col cols="auto">
            <v-btn text @click="previousStep">
              <v-icon>mdi-chevron-left</v-icon>
              {{ $t("order.back") }}
            </v-btn>
          </v-col>
          <v-col class="d-flex flex-row-reverse" cols="auto">
            <v-btn color="secondary" text @click="cancel"
              >{{ $t("cancel") }}
            </v-btn>
          </v-col>
          <v-spacer />
          <v-col class="d-flex flex-row-reverse ml-4" cols="auto">
            <v-btn
              color="primary"
              rounded
              @click="nextStep"
              :disabled="helper.resolutions.length === 0"
              >{{ $t("continue") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import OrderStepper from "@/core/components/order/OrderStepper";
import StepHeader from "@/core/components/order/StepHeader.vue";
import Vue from "vue";
import { mapActions } from "vuex";

export default {
  name: "ResolutionStep",
  components: { StepHeader, OrderStepper },
  props: {
    value: Number,
    order: Object,
    helper: Object
  },
  data() {
    return {
      step: this.value,
      resolutions: [
        {
          id: "coarse",
          sensorIds: ["sn3"],
          name: this.$t("order.resolutionStep.coarse"),
          imageFrequency: this.$t("daily"),
          resolution: "300 m",
          sensors: "Sentinel-3",
          description: this.$t("order.resolutionStep.coarseDescription"),
          image:
            "https://eoimages.gsfc.nasa.gov/images/imagerecords/147000/147388/laptevsea_amo_2020254_front.jpg"
        },
        {
          id: "fine",
          sensorIds: ["sn2", "ls8", "ls9"],
          name: this.$t("order.resolutionStep.fine"),
          imageFrequency: `3-8 ${this.$t("days")}`,
          resolution: "10-30 m",
          sensors: "Sentinel-2, Landsat 8/9",
          description: this.$t("order.resolutionStep.fineDescription"),
          image: require("/src/core/assets/eoportal/Zoom_Cahora_20200127_S2_hell.jpg")
        },
        {
          id: "planet",
          sensorIds: ["sdo"],
          name: this.$t("order.resolutionStep.planet"),
          imageFrequency: this.$t("daily"),
          resolution: "3 m",
          sensors: "Planet SuperDove",
          description: this.$t("order.resolutionStep.planetDescription"),
          image: require("/src/core/assets/eoportal/vhr_planet_demobild_small.png")
        }
      ]
    };
  },
  computed: {
    allowedResolutions() {
      const hasEomapRole = Vue.prototype.$keycloak.realmAccess?.roles.includes(
        "eomap"
      );
      const client = Vue.prototype.$appConfig.keycloakClient;
      const roles = this.$keycloak.resourceAccess?.[client]?.roles;
      let hasPlanetRole = false;
      if (roles?.length) {
        hasPlanetRole = roles.includes("planet");
      }
      return hasEomapRole || hasPlanetRole
        ? this.resolutions
        : this.resolutions.filter(obj => obj.id !== "planet");
    }
  },
  watch: {
    "helper.resolutions": {
      handler() {
        let sensorIds = [];
        this.resolutions.forEach(elem => {
          if (this.helper.resolutions.includes(elem.id)) {
            sensorIds = sensorIds.concat(elem.sensorIds);
          }
        });
        this.helper.possibleSensors = JSON.parse(JSON.stringify(sensorIds));
        this.order.sensors = sensorIds;
        this.helper.possibleScenes = [[]];
      },
      deep: true
    }
  },
  methods: {
    ...mapActions("app", ["showSnackbar"]),
    disableCard(resolutionId) {
      if (resolutionId === "planet") {
        return (
          this.helper.resolutions.includes("coarse") ||
          this.helper.resolutions.includes("fine")
        );
      } else {
        return this.helper.resolutions.includes("planet");
      }
    },
    removeSstInPlanet() {
      if (this.helper.resolutions[0] == "planet") {
        const index = this.order.products.indexOf("sst");
        if (index > 0) {
          this.order.products.splice(index, 1);
          this.showSnackbar({
            show: true,
            message: this.$t("order.resolutionStep.removedSST"),
            color: "error",
            timeout: 6000
          });
        }
      }
    },
    nextStep() {
      this.removeSstInPlanet();
      this.$emit("updateOrder", this.order);
      this.$emit("updateHelper", this.helper);
      this.$emit("input", this.step + 1);
    },
    previousStep() {
      this.removeSstInPlanet();
      this.$emit("updateHelper", this.helper);
      this.$emit("input", this.step - 1);
    },
    cancel() {
      this.$router.push({ name: "Regions" });
    }
  }
};
</script>

<style scoped>
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
</style>
