<template>
  <v-dialog
    v-model="dialog"
    width="auto"
    max-width="455"
    persistent
    style="z-index: 99"
  >
    <v-card id="canvas " ref="canvas" class="mx-auto">
      <v-card
        elevation="0"
        class="white--text align-end"
        height="120px"
        style="background-color: #d7f3e1"
      >
        <v-btn class="icon-check" style="background-color: #35C168" icon fab>
          <v-icon color="white" large>mdi-check</v-icon>
        </v-btn>
      </v-card>
      <v-card-title class="pt-8  d-flex justify-center align-center">
      </v-card-title>

      <v-card-text class="pt-3 text-center align-center">
        <v-row>
          {{ $t("order.viewAndPayStep.creditsPurchase") }}
        </v-row>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn color="primary" text @click="closeDialog">
          {{ $t("order.viewAndPayStep.continue") }}
        </v-btn>
        <v-spacer />
        <v-btn color="primary" text rounded @click="backToOverview">
          {{ $t("order.viewAndPayStep.leaveOrder") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "PurchaseSuccessDialog",
  props: {
    dialog: Boolean
  },
  methods: {
    ...mapActions("management", ["fetchRegions"]),
    backToOverview() {
      this.$router.push({ name: "Regions" });
      this.fetchRegions();
    },
    closeDialog() {
      this.$emit("close-dialog");
    }
  }
};
</script>

<style scoped>
.icon-check {
  position: absolute;
  top: 90px;
  left: 195px;
}

.icon-text {
  /* v-text/body-small */
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.25px;
}

.icon-symbol {
  font-size: 38px;
}
</style>
