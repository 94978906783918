<template>
  <div class="d-flex flex-column full-height">
    <div class="flex-grow-0">
      <order-stepper v-model="step" />
      <step-header
        :step="step"
        :title="'order.defineTimeRangeStep.defineTimeRange'"
        :sub-title="'order.defineTimeRangeStep.defineTimerangeDefinition'"
      ></step-header>
    </div>
    <div class="flex-grow-1 pl-13 pt-10">
      <v-container>
        <!--        <v-row class="mx-0 pb-5" justify="space-between">-->
        <!--          <v-card width="49%">-->
        <!--            <v-card-title>-->
        <!--              <v-row class="px-3">-->
        <!--                <v-radio-group v-model="mode">-->
        <!--                  <v-radio value="range"></v-radio>-->
        <!--                </v-radio-group>-->
        <!--                <v-col>-->
        <!--                  <v-row>-->
        <!--                    <span class="heading-4-bold">{{-->
        <!--                      $t("order.timeRange")-->
        <!--                    }}</span>-->
        <!--                  </v-row>-->
        <!--                  <v-row>-->
        <!--                    <v-card-subtitle class="pl-0">-->
        <!--                      <span class="body-default">-->
        <!--                        {{-->
        <!--                          $t("order.defineTimeRangeStep.timeRangeDescription")-->
        <!--                        }}-->
        <!--                      </span>-->
        <!--                    </v-card-subtitle>-->
        <!--                  </v-row>-->
        <!--                </v-col>-->
        <!--              </v-row>-->
        <!--            </v-card-title>-->
        <!--          </v-card>-->
        <!--        </v-row>-->
        <range-card
          :timeranges="helper.timeranges"
          @updateTimeranges="updateTimeranges"
        ></range-card>
      </v-container>
    </div>
    <div class="flex-grow-0">
      <v-row
        no-gutters
        class="d-flex flex-row pa-5"
        style="background-color: white"
        align="center"
      >
        <v-col cols="auto">
          <v-btn text @click="previousStep">
            <v-icon>mdi-chevron-left</v-icon>
            {{ $t("order.back") }}
          </v-btn>
        </v-col>
        <v-col class="d-flex flex-row-reverse" cols="auto">
          <v-btn color="secondary" text @click="cancel"
            >{{ $t("cancel") }}
          </v-btn>
        </v-col>
        <v-spacer />
        <v-col class="d-flex flex-row-reverse ml-4" cols="auto">
          <v-btn
            v-if="!loading"
            color="primary"
            rounded
            @click="nextStep"
            :disabled="
              !helper.timeranges[0].startDate ||
                !helper.timeranges[0].endDate ||
                helper.timeranges.filter(range => range.tag === 'error')
                  .length >= 1
            "
            >{{ $t("continue") }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import RangeCard from "@/core/components/order/steps/defineTimeRange/RangeCard.vue";
import StepHeader from "@/core/components/order/StepHeader.vue";
import OrderStepper from "@/core/components/order/OrderStepper.vue";
import priceCalculationMixin from "@/core/mixins/priceCalculation.mixin";

export default {
  name: "DefineTimeRange",
  mixins: [priceCalculationMixin],
  components: { OrderStepper, StepHeader, RangeCard },
  props: {
    value: Number,
    order: Object,
    helper: Object
  },
  data() {
    return {
      step: this.value,
      startDate: "",
      startMenu: false,
      endDate: "",
      endMenu: false,
      mode: "range",
      loading: false
    };
  },
  methods: {
    nextStep() {
      this.$emit("updateOrder", this.order);
      this.$emit("updateHelper", this.helper);
      this.$emit("input", this.step + 1);
    },
    previousStep() {
      this.$emit("updateOrder", this.order);
      this.$emit("updateHelper", this.helper);
      this.$emit("input", this.step - 1);
    },

    checkTimeranges() {
      if (this.helper.timeranges.length) {
        this.helper.timeranges.forEach((range, index) => {
          this.helper.sceneCount[index] = 0;
          this.helper.possibleScenes[index] = [];
          if (!range.startDate && !range.endDate) {
            this.helper.timeranges.splice(index, 1);
          }
        });
      }
    },
    cancel() {
      this.$router.push({ name: "Regions" });
    },
    updateTimeranges(value) {
      this.loading = true;
      this.order.selectedScenes = [];
      this.helper.timeranges = value;
      this.helper.seasons = {
        months: [],
        years: []
      };
      this.helper.scenePriceFuture = 0;
      this.helper.scenePriceHistoric = 0;
      this.checkTimeranges();
      this.order.timerange = this.helper.timeranges[0];
      this.loading = false;
    }
  }
};
</script>

<style>
.full-height {
  height: 100%;
}
</style>
