import axios from "axios";
import Vue from "vue";

const projAxios = axios.create({
  baseURL: "https://proj.api.eomap.com"
});

export const postProj = async file => {
  const formData = new FormData();
  formData.append("file", file);

  const response = await projAxios.post("convert", formData, {
    headers: {
      Authorization: `Bearer ${Vue.prototype.$keycloak.token}`,
      "Content-Type": "multipart/form-data",
      accept: "application/json"
    }
  });
  return response.data;
};
