var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('basemap-switch-order',{attrs:{"map":_vm.map,"layers":_vm.basemapLayer,"iconStyles":{
      marginTop: '26px',
      backgroundColor: 'transparent',
      iconColor: '#3d508f'
    }}}),_c('show-sensor-tiles',{attrs:{"map":_vm.map,"iconStyles":{
      marginTop: '26px',
      backgroundColor: 'transparent',
      iconColor: '#3d508f'
    }}}),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: onTooltip }){return [_c('v-btn',_vm._g({staticClass:"btn-fix",attrs:{"id":"selection-zoom","tile":"","absolute":"","top":"","right":"","fab":"","x-small":"","elevation":"0"},on:{"click":function($event){return _vm.$emit('zoomSelection')}}},{ ...onTooltip }),[_c('v-icon',{attrs:{"color":"grey darken-3"}},[_vm._v("mdi-crosshairs")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("order.defineAoiStep.zoomToSelection")))])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: onTooltip }){return [_c('v-btn',_vm._g({staticClass:"btn-fix",attrs:{"id":"delete-all","tile":"","absolute":"","top":"","right":"","fab":"","x-small":"","elevation":"0"},on:{"click":function($event){return _vm.$emit('deleteAll')}}},{ ...onTooltip }),[_c('v-icon',[_vm._v("mdi-broom")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("order.defineAoiStep.deleteAllGeometries")))])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }