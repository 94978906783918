<template>
  <div>
    <basemap-switch-order
      :map="map"
      :layers="basemapLayer"
      :iconStyles="{
        marginTop: '26px',
        backgroundColor: 'transparent',
        iconColor: '#3d508f'
      }"
    ></basemap-switch-order>
    <show-sensor-tiles
      :map="map"
      :iconStyles="{
        marginTop: '26px',
        backgroundColor: 'transparent',
        iconColor: '#3d508f'
      }"
    ></show-sensor-tiles>
    <v-tooltip left>
      <template #activator="{ on: onTooltip }">
        <v-btn
          id="selection-zoom"
          tile
          absolute
          top
          right
          fab
          x-small
          elevation="0"
          v-on="{ ...onTooltip }"
          class="btn-fix"
          @click="$emit('zoomSelection')"
        >
          <v-icon color="grey darken-3">mdi-crosshairs</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("order.defineAoiStep.zoomToSelection") }}</span>
    </v-tooltip>
    <v-tooltip left>
      <template #activator="{ on: onTooltip }">
        <v-btn
          id="delete-all"
          tile
          absolute
          top
          right
          fab
          x-small
          elevation="0"
          v-on="{ ...onTooltip }"
          class="btn-fix"
          @click="$emit('deleteAll')"
        >
          <v-icon>mdi-broom</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("order.defineAoiStep.deleteAllGeometries") }}</span>
    </v-tooltip>
  </div>
</template>
<script>
import mapboxMixins from "@/core/mixins/mapbox.mixins";
import BasemapSwitchOrder from "@/core/components/order/steps/defineAoi/BasemapSwitchOrder.vue";
import ShowSensorTiles from "@/core/components/order/steps/defineAoi/ShowSensorTiles.vue";
export default {
  name: "MapMenu",
  mixins: [mapboxMixins],
  components: {
    ShowSensorTiles,
    BasemapSwitchOrder
  },
  data() {
    return {
      isSelecting: false
    };
  },
  props: {
    map: Object
  },
  computed: {
    mapStyle() {
      return this.getMapStyle();
    },
    basemapLayer() {
      if (this.$appConfig.keycloakClient === "coasts") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.mapStyle.layers[2].layout.visibility = "visible";
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.mapStyle.layers[0].layout.visibility = "none";
      }
      return this.mapStyle.layers.filter(item => item.id.startsWith("basemap"));
    }
  },
  methods: {
    zoomToSelection() {
      const selectionGeojson = this.draw.getAll();
      this.zoomToBBox(selectionGeojson);
    }
  }
};
</script>
<style scoped>
#selection-zoom {
  margin-top: 67px;
}

#delete-all {
  margin-top: 104px;
}

.btn-fix {
  border: 1px solid lightgrey;
  margin-right: -7px;
  background-color: white;
  padding: 18px 18px;
}

.btn-fix:focus::before {
  opacity: 0 !important;
}

.btn-fix:hover::before {
  opacity: 0.08 !important;
}
</style>
