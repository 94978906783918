var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"d-flex align-center"},[(
      !_vm.filteredScenes.length &&
        !_vm.possibleScenes.length &&
        _vm.helper.historicRanges.length
    )?_c('v-row',{staticStyle:{"align-items":"center"}},[_c('v-col',{attrs:{"align":"center"}},[_c('v-card',{staticClass:"rounded-circle text-center d-flex flex-column align-center justify-center",staticStyle:{"background-color":"#E7E6EC","outline-style":"dashed","outline-color":"grey"},attrs:{"elevation":"0","width":"20vh","height":"20vh","rounded":""}},[_c('v-icon',{attrs:{"size":"15vh"}},[_vm._v("mdi-magnify")])],1)],1),_c('v-col',[_c('v-row',{staticClass:"pt-5 font-weight-light heading-4"},[_vm._v(" "+_vm._s(_vm.$t("order.sceneSelectionStep.noScenesFound"))+" ")]),_c('v-row',{staticClass:"pt-5"},[_vm._v(" "+_vm._s(_vm.$t("order.sceneSelectionStep.changeDateRange"))+" ")]),_c('v-row',{staticClass:"pt-10"})],1)],1):_vm._e(),(
      !_vm.filteredScenes.length &&
        _vm.possibleScenes.length &&
        _vm.helper.historicRanges.length
    )?_c('v-row',{staticStyle:{"align-items":"center"}},[_c('v-col',{attrs:{"align":"center"}},[_c('v-card',{staticClass:"rounded-circle text-center d-flex flex-column align-center justify-center",staticStyle:{"background-color":"#E7E6EC","outline-style":"dashed","outline-color":"grey"},attrs:{"elevation":"0","width":"20vh","height":"20vh","rounded":""}},[_c('v-icon',{attrs:{"size":"15vh"}},[_vm._v("mdi-magnify-remove-outline")])],1)],1),_c('v-col',[_c('v-row',{staticClass:"pt-5 font-weight-light heading-4"},[_vm._v(" "+_vm._s(_vm.$t("order.sceneSelectionStep.noResultsFound"))+" ")]),_c('v-row',{staticClass:"pt-5"},[_vm._v(" "+_vm._s(_vm.$t("order.sceneSelectionStep.noResultsDescription"))+" ")]),_c('v-row',{staticClass:"pt-10"},[_vm._v(" "+_vm._s(_vm.$t("order.sceneSelectionStep.changeFilterSettings"))+" ")])],1)],1):_vm._e(),(!_vm.helper.historicRanges.length)?_c('v-row',{staticStyle:{"align-items":"center"}},[_c('v-col',{attrs:{"align":"center"}},[_c('v-card',{staticClass:"rounded-circle text-center d-flex flex-column align-center justify-center",staticStyle:{"background-color":"#E7E6EC","outline-style":"dashed","outline-color":"grey"},attrs:{"elevation":"0","width":"20vh","height":"20vh","rounded":""}},[_c('v-icon',{attrs:{"size":"15vh"}},[_vm._v("mdi-magnify-remove-outline")])],1)],1),_c('v-col',[_c('v-row',{staticClass:"pt-5"},[_vm._v(" "+_vm._s(_vm.$t("order.sceneSelectionStep.noSceneSelectionText"))+" ")])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }